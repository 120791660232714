{
    "Account_DeleteSavedCCModal_Confirm": "Tak, usuń",
    "Account_DeleteSavedCCModal_Error_Button": "Wróć",
    "Account_DeleteSavedCCModal_Error_Text": "Wystąpił błąd podczas usuwania karty.\nWróć do listy kart i spróbuj ponownie.\n",
    "Account_DeleteSavedCCModal_Error_Title": "Wystąpił błąd",
    "Account_DeleteSavedCCModal_Processing_Text": "Usuwamy Twoją kartę…",
    "Account_DeleteSavedCCModal_Text": "Czy na pewno chcesz usunąć zapisaną kartę?",
    "Account_DeleteSavedCCModal_Title": "Usuń kartę",
    "Account_ManageSavedCC_Error": "Wystąpił błąd. Nie udało się załadować Twoich kart.",
    "Account_ManageSavedCC_NoCards": "Brak zapisanych kart",
    "Account_ManageSavedCC_Title": "Twoje zapisane karty",
    "Auth_InvalidCredentials": "Podane hasło jest nieprawidłowe",
    "Auth_InvalidTokenMessage": "Prawdopodobnie twój link wygasł",
    "Blik_Approve_Subtitle": "Jeśli tego nie zrobisz,\npłatność nie powiedzie się.\n",
    "Blik_Approve_Title": "Teraz potwierdź płatność\nw swojej aplikacji bankowej\n",
    "Blik_Code": "Kod Blik",
    "Blik_Code_Expired": "Kod wygasł. Spróbuj ponownie.",
    "Blik_Code_Info": "Wpisz 6-cyfrowy kod BLIK – znajdziesz go w swojej aplikacji bankowej.",
    "Blik_Code_Invalid": "Błędny kod. Spróbuj ponownie.",
    "Blik_Code_Used": "Kod został już wykorzystany. Spróbuj ponownie.",
    "Blik_Error_PaymentFailed": "Płatność nie udała się. Spróbuj ponownie",
    "Blik_Name_SavedToken": "Twój zapisany Blik bez kodu",
    "Blik_Name_WithCode": "Kod Blik",
    "Blik_PayWithBlik": "Zapłać Blikiem",
    "Blik_TokenInfo_Text": "Włącz w swojej aplikacji bankowej opcję zakupu bez kodu dla Lekko.",
    "Blik_TokenInfo_Title": "Chcesz płacić szybciej?",
    "Blik_Tokens_Info": "Wybierz jeden z zapisanych Blików",
    "Blik_UseNewCode": "Wpisz nowy kod Blik",
    "Blog": "Blog",
    "Blog_Author": "Autor",
    "Blog_Category_All": "Wszystkie",
    "Blog_Disclaimer_Bold": "Ten artykuł ma charakter wyłącznie informacyjny i nie stanowi porady medycznej.",
    "Blog_Disclaimer_Text": "Informacje zawarte w niniejszym dokumencie nie zastępują i nigdy nie powinny być traktowane jako profesjonalna porada medyczna.",
    "Blog_ReadAlso": "Przeczytaj również",
    "Blog_ReadingTime": "0 minut czytania | 1 minuta czytania | {n} minuty czytania | {n} minut czytania",
    "Blog_Remember": "Warto zapamiętać",
    "Blog_Share": "Podziel się",
    "Blog_Share_Email_Subject": "Lekko Blog - Przeczytaj {postTitle}",
    "Blog_Share_Email_Text": "Hej, przeczytaj ten wpis {postTitle} - {postUrl}",
    "Blog_Sources": "Źródła wiedzy",
    "Blog_Start_Description": "To miejsce powstało z troski o najwyższą jakość treści, sprawdzone źródła wiedzy i różnorodne perspektywy ekspertów z różnych dziedzin. Przejdź na stronę nauki i zaczytaj się w niej na dobre.",
    "Blog_Start_Title": "Blog",
    "Blog_Title": "{title} {'|'} Blog Lekko",
    "CareClub_Coinback": "{title} - za to zamówienie otrzymasz:",
    "CareClub_Coinback_Strong": "lekko club",
    "CareClub_Dashboard_0_Coins_Title": "Tutaj pojawią się Twoje {coins}",
    "CareClub_Dashboard_0_Coins_Title_Coins": "smiles!",
    "CareClub_Dashboard_0_LoggedIn_AcceptTerms": "zaakceptuj zgody na komunikację marketingową",
    "CareClub_Dashboard_0_LoggedIn_AcceptTerms_Small": "dzięki nim, będziemy mogli informować Cię o Twoich smiles",
    "CareClub_Dashboard_0_LoggedIn_ConfirmEmail": "potwierdź adres e-mail",
    "CareClub_Dashboard_0_LoggedIn_Join": "dołączam",
    "CareClub_Dashboard_0_LoggedIn_Join_Text": "Na warunkach wskazanych w Załączniku nr 2 do Regulaminu Sklepu - Regulamin Programu lekko club.\n",
    "CareClub_Dashboard_0_LoggedIn_MakeFirstOrder": "złóż przynajmniej jedno zamówienie na {link}",
    "CareClub_Dashboard_0_LoggedIn_Register": "załóż konto",
    "CareClub_Dashboard_0_LoggedIn_SentConfirmationEmail": "Na Twoj adres e-mail wysłaliśmy wiadomość z linkiem potwierdzającym.\n",
    "CareClub_Dashboard_0_LoggedIn_SentConfirmationEmail_Error": "Wystąpił błąd, spróbuj ponownie",
    "CareClub_Dashboard_0_LoggedIn_Text": "Co musisz zrobić, żeby skorzystać ze swoich smiles?",
    "CareClub_Dashboard_0_LoggedIn_Waiting": "Zaliczenie warunków dołączenia może potrwać do 5 minut.",
    "CareClub_Dashboard_1_Coins_Description": "",
    "CareClub_Dashboard_1_Coins_Title": "",
    "CareClub_Dashboard_1_Coins_Title_Coins": "",
    "CareClub_Dashboard_1_Title": "",
    "CareClub_Dashboard_Benefits_CurrentLevel": "Twoje benefity na poziomie 3 - BEST FLOW",
    "CareClub_Dashboard_Benefits_FreeShipping": "{bold} dostawa od {money}",
    "CareClub_Dashboard_Benefits_FreeShipping_Bold": "darmowa",
    "CareClub_Dashboard_Benefits_NextLevel": "Co zyskujesz na poziomie {level} - {levelTitle}?",
    "CareClub_Dashboard_Benefits_Text": "budujesz zdrowe nawyki i regularność",
    "CareClub_Dashboard_Smiles": "smiles",
    "CareClub_Dashboard_CoinBack": "Co to znaczy? {percent} wartości produktów z Twojego zamówienia wraca do Ciebie jako {strong}\n",
    "CareClub_Dashboard_CoinBack_Short": "{percent} smilesback",
    "CareClub_Dashboard_CoinBack_Title": "Twój smilesback to",
    "CareClub_Dashboard_Coins_Description": "1 smiles = 1 {currency} ZNIŻKI",
    "CareClub_Dashboard_Coins_Title_CareCoins": "smiles",
    "CareClub_Dashboard_Coins_Title_Coins": "smiles!",
    "CareClub_Dashboard_Coins_Title_ToBeAble": "Tutaj pojawią się Twoje {coinsTitle}",
    "CareClub_Dashboard_Coins_Title_WaitingForYou": "Czeka na Ciebie {coinsQuantity} {coinsTitle}",
    "CareClub_Dashboard_Coins_Title_YouHave": "Masz teraz {coinsQuantity} {coinsTitle}",
    "CareClub_Dashboard_ExternalForm_Btn": "wypełniam",
    "CareClub_Dashboard_ExternalForm_Text": "Wypełnij ankietę ponownie jeżeli zmieniły się Twoje cele lub potrzeby.  Za ponowne wypełnienie nie otrzymasz smiles.\n",
    "CareClub_Dashboard_ExternalForm_Title": "Wypełnij ankietę",
    "CareClub_Dashboard_Header_HelloCustomer": "Cześć {name}, wymieniaj swoje {strong} na zniżki!",
    "CareClub_Dashboard_Header_HelloCustomer_Strong": "smiles",
    "CareClub_Dashboard_Header_ImageText": "lekko club",
    "CareClub_Dashboard_Level1_Small": "Poziom 1",
    "CareClub_Dashboard_Level1_Strong": "Start",
    "CareClub_Dashboard_Level1_Title": "Little Joy",
    "CareClub_Dashboard_Level2_Small": "Poziom 2",
    "CareClub_Dashboard_Level2_Strong": "Care",
    "CareClub_Dashboard_Level2_Title": "Big Happy",
    "CareClub_Dashboard_Level3_Small": "Poziom 3",
    "CareClub_Dashboard_Level3_Strong": "Hero",
    "CareClub_Dashboard_Level3_Title": "Best flow",
    "CareClub_Dashboard_LevelInfo_Level1_Title": "Wejdź na {level} poziom, osiągnij {levelTitle}",
    "CareClub_Dashboard_LevelInfo_Level2_Title": "Wejdź na {level} poziom, zostań {levelTitle}",
    "CareClub_Dashboard_LevelInfo_Level3_Title": "Oto jesteś na BEST FLOW️",
    "CareClub_Dashboard_LevelInfo_Points": "Masz {coinsEarned} z {coinsToNextLevel} potrzebnych do awansu na poziom {levelName}.",
    "CareClub_Dashboard_LevelInfo_Title_CurrentLevel": "",
    "CareClub_Dashboard_LevelsTitle": "Twój status",
    "CareClub_Dashboard_RegularityInfo_Level1_Title": "Krok 1. {strong}",
    "CareClub_Dashboard_RegularityInfo_Level1_Title_Strong": "Zbierz {coinsToNextLevel} smiles",
    "CareClub_Dashboard_RegularityInfo_Level2_Title": "Zbierz {coinsToNextLevel} smiles",
    "CareClub_Dashboard_RegularityInfo_Level3_Text": "Czujemy, że Twoja regularność i codzienne starania, by czuć się lekko odpłacają się tym, że żyjesz z super lekko best flow channel! Myślimy tak nie bez powodu. Flow channel to teoria która zakłada, że by dobrze żyć i fajnie się rozwijać trzeba poruszać się we flow, które nie buduje za dużo stresu ani nie wprowadza w marazm. Tak trzymaj i {strong}!\n",
    "CareClub_Dashboard_RegularityInfo_Level3_Text_Strong": "take it easy and lekko",
    "CareClub_Dashboard_RegularityInfo_Level3_Title": "Ty to wiesz, że można {strong}!",
    "CareClub_Dashboard_RegularityInfo_Level3_Title_Strong": "lekko",
    "CareClub_Dashboard_RegularityInfo_Text_ToLevel2": "Bierzemy pod uwagę wszystkie {strong} jakie otrzymałaś od momentu dołączenia do lekko club, również te, które wymieniłaś już na rabat.\n",
    "CareClub_Dashboard_Text_Smiles": "smiles",
    "CareClub_Dashboard_Text_LekkoClub": "lekko club",
    "CareClub_Dashboard_Text_BigHappy": "BIG HAPPY",
    "CareClub_Dashboard_RegularityInfo_Text_Smiles": "smiles",
    "CareClub_Dashboard_RegularityInfo_Text_ToLevel3": "Bierzemy pod uwagę wszystkie smiles jakie otrzymałaś od momentu wejścia na poziom 2, również te, które wymieniłaś już na rabat.\n",
    "CareClub_Dashboard_RegularityInfo_Title": "",
    "LekkoClub_Dashboard_Title": "Za każdy zakup dostaniesz {strong}!",
    "LekkoClub_Dashboard_Title_Strong": "lekko smiles",
    "LekkoClub_Dashboard_Subtitle": "A uśmiechy wymienisz na {strong}",
    "LekkoClub_Dashboard_Subtitle_Strong": "zniżki!",
    "CareClub_History_Available_HideMore": "- ukryj aktywność ({quantity})",
    "CareClub_History_Available_ShowMore": "+ pokaż całą aktywność ({quantity})",
    "CareClub_History_Coins": "Masz teraz {strong}",
    "CareClub_History_Coins_Strong": "smiles",
    "CareClub_History_EmptyHistoryPlaceholder": "Zostajesz członkiem lekko club",
    "CareClub_History_Subtitle": "Twoja aktywność",
    "CareClub_History_Title": "Twoja historia",
    "CareClub_LevelTitle": "{start} {end}",
    "CareClub_LevelTitle_1_End": "Start",
    "CareClub_LevelTitle_1_Start": "Healthy",
    "CareClub_LevelTitle_2_End": "HAPPY",
    "CareClub_LevelTitle_2_Start": "BIG",
    "CareClub_LevelTitle_3_End": "FLOW",
    "CareClub_LevelTitle_3_Start": "BEST",
    "CareClub_QuestCard_ActiveUntil": "Aktywny do: {date}",
    "CareClub_QuestCard_AvailableOnLevel": "Dostępny na: Poziomie {levelTitle}",
    "CareClub_QuestCard_IsDone": "{bold} \uD83C\uDF89 smiles za to zadanie są już na Twoim koncie.",
    "CareClub_QuestCard_IsDone_Bold": "Brawo",
    "CareClub_QuestCard_ReadMore": "Czytaj więcej",
    "CareClub_Quests_Available_HideMore": "- ukryj wykonane zadania ({quantity})",
    "CareClub_Quests_Available_ShowMore": "+ pokaż wykonane zadania ({quantity})",
    "CareClub_Quests_Available_Subtitle": "Realizuj zadania i zdobywaj dodatkowe smiles za zakupy oraz niezależnie od składanych zamówień. Każde z zadań wspiera Twoje starania na rzecz budowania zdrowego stylu życia i lepszego samopoczucia.\n",
    "CareClub_Quests_Available_Title": "Twoje zadania",
    "CareClub_Quests_Example_Subtitle": "{strong} możesz zdobywać dzięki różnym zadaniom. Zrób jedno z nich, a na dobry start dostaniesz od nas jeszcze więcej uśmiechów!\n",
    "CareClub_Quests_Example_Title": "Za te zadania możesz mieć więcej punktów",
    "CareClub_Quests_Next_HideMore": "- ukryj wszystkie nadchodzące zadania",
    "CareClub_Quests_Next_ShowMore": "+ pokaż wszystkie nadchodzące zadania",
    "CareClub_Quests_Next_Subtitle": "Dostępne na kolejnych poziomach.",
    "CareClub_Quests_Next_Title": "Nadchodzące zadania",
    "CareClub_YourRoad_HeraYouIs": "tu jesteś",
    "CareClub_YourRoad_LevelNumber": "Poziom {level}",
    "Cart_CantAdd": "Nie możesz dodać więcej produktów",
    "Cart_Checkout_Total": "Suma zamówienia",
    "CartCoinsInfo_Title_CoinsToUse": "Masz do wykorzystania {quantity} smiles, które dadzą Ci rabat w wysokości {value}!\n",
    "CartCoinsInfo_Title_UsedCoins": "Kupujesz z {buy} {currency}",
    "CartCoinsInfo_Title_UsedCoins_Buy": "rabatem klubowym lekko club",
    "Cart_Coins_Remove": "",
    "CartCouponInfo_Title_UsedCoupon": "{buy} kodem rabatowym {couponName}",
    "CartCouponInfo_Title_UsedCoupon_Buy": "Kupujesz z",
    "CartCouponInfo_Title_Waiting": "Kod jeszcze nieaktywny",
    "Cart_Coupon_Remove": "",
    "Cart_DiscoundWidget_Coins_ButtonUndo": "",
    "Cart_DiscoundWidget_Coins_Button_Undo": "Cofnij",
    "Cart_DiscoundWidget_Coins_ButtonUse": "",
    "Cart_DiscoundWidget_Coins_Button_Use": "Użyj",
    "Cart_DiscoundWidget_Coins_Description": "W tym zamówieniu możesz wykorzystać {maxCoins} 1 smiles = 1 PLN rabatu. Maksymalny rabat klubowy to 50% wartości produktów w koszyku.",
    "Cart_DiscoundWidget_Coins_Description_MaxCoins": "smiles.",
    "Cart_DiscoundWidget_Coins_Description_Used": "Korzystasz z rabatu w wysokości {sumCoins} {currency}.",
    "Cart_DiscoundWidget_Coins_Title": "Masz {sumCoins} {name} na koncie",
    "Cart_DiscoundWidget_Coins_Title_Name": "smiles",
    "Cart_DiscoundWidget_Coupon_Button_Add": "Dodaj",
    "Cart_DiscoundWidget_Coupon_Button_Change": "Zmień",
    "Cart_DiscoundWidget_Coupon_Label_Success": "Twój aktywny kod rabatowy",
    "Cart_DiscoundWidget_Description": "Pamiętaj, że punkty klubowe i kod rabatowy nie łączą się.",
    "Cart_DiscoundWidget_Header": "Wybierz swój rabat",
    "Cart_DiscoundWidget_IsGuest_Coins_Description": "{join} już teraz, a za to zamówienie otrzymasz {coinback} {currency} rabatu na kolejne zakupy. Jesteś już członkiem? {login}!\n",
    "Cart_DiscoundWidget_IsGuest_Coins_Description_Join": "Dołącz do lekko club",
    "Cart_DiscoundWidget_IsGuest_Coins_Description_Login": "Zaloguj się",
    "Cart_DiscoundWidget_IsGuest_Coins_Title": "Tutaj pojawi się {bold}",
    "Cart_DiscoundWidget_IsGuest_Coins_Title_Bold": "Twój rabat",
    "Cart_DiscoundWidget_IsLevel0_Coins_Description": "{join} już teraz, a za to zamówienie otrzymasz {coinback} {currency} rabatu na kolejne zakupy.\n",
    "Cart_DiscoundWidget_Tab_Coins": "Rabat lekko club",
    "Cart_DiscoundWidget_Tab_Coupon": "Kod rabatowy",
    "Cart_Discount_Remove": "Usuń rabat",
    "Cart_DiscountWidget_OnlySingleDiscount": "Pamiętaj, że możesz skorzystać tylko z jednego rabatu.",
    "Cart_Empty_SeeProducts": "Zamawiam teraz",
    "Cart_Empty_Text": "Sięgnij po inteligenty self-care, by łatwiej się uśmiechać, mniej stresować i znaleźć równowagę w tym szalonym świecie. Moc masz w sobie. Czasami tylko potrzebujesz lekkiego wsparcia, by jej pomóc.",
    "Cart_Empty_Title": "Twój koszyk jest pusty.",
    "Cart_FreeShipping_FreeShipment": "Twój koszyk przekroczył wartość {threshold}, więc {label}!",
    "Cart_FreeShipping_FreeShipmentAvailable": "Twój koszyk przekroczył wartość {threshold}, możesz wybrać {label}!",
    "Cart_FreeShipping_FreeShipmentAvailable_Label": "dostawę na nasz koszt",
    "Cart_FreeShipping_FreeShipment_Free": "Dostawa jest na nasz koszt",
    "Cart_FreeShipping_FreeShipment_Label": "dostawa jest na nasz koszt",
    "Cart_FreeShipping_MissingAmount": "Brakuje Ci {amount} do {label}.",
    "Cart_FreeShipping_MissingAmount_Label": "darmowej dostawy",
    "Cart_FreeShipping_MissingAmount_Only": "Brakuje Ci tylko {amount} do {label}.",
    "Cart_GoToCheckout": "Zamawiam teraz",
    "Cart_isAboveMaxValue": "Nie można zrealizować zamówienia",
    "Cart_Item_Gift_Text": "otrzyma kartę, którą wyślemy na podanego maila po zrealizowaniu płatności.",
    "Cart_Item_OutOfStock": "Produkt jest niestety niedostępny.",
    "Cart_Item_PriceForOne": "{price} za 1 szt.",
    "Cart_Item_Remove": "Usuń",
    "Cart_Item_Removed_Restore": "Przywróć",
    "Cart_Item_Removed_Text": "Usunęłaś {product} z koszyka",
    "Cart_Item_Remove_Long": "Usuń z koszyka",
    "Cart_MaxValieInfo_Warn_Text": "W związku ze zmianami wynikającymi z Brexit, maksymalna kwota pojedynczego zamówienia wysyłanego do Wielkiej Brytanii wynosi {maxValue}.  Przepraszamy za niedogodności, pracujemy nad umożliwieniem składania większych zamówień.",
    "Cart_MaxValueInfo_Error_Line1": "W związku ze zmianami wynikającymi z Brexit, maksymalna kwota pojedynczego zamówienia wysyłanego do Wielkiej Brytanii wynosi {maxValue}.  Przepraszamy za niedogodności, pracujemy nad umożliwieniem składania większych zamówień.",
    "Cart_MaxValueInfo_Error_Line2": "Aby zrealizować zamówienie, usuń produkty z koszyka tak, aby kwota zamówienia nie przekraczała {maxValue}",
    "Cart_MaxValueInfo_Error_Title": "Maksymalna wartość zamówienia to {maxValue}",
    "Cart_MaxValueInfo_Warn_Title": "Maksymalna wartość zamówienia to {maxValue}",
    "Cart_OrderError_Cart": "wróć do koszyka",
    "Cart_OrderError_ContactUs": "napisz do nas",
    "Cart_OrderError_Line1": "Wystąpił błąd podczas składania zamówienia.",
    "Cart_OrderError_Line2": "Spróbuj ponownie lub {backToCart}, aby upewnić się, że wszystkie wybrane produkty są dostępne.",
    "Cart_OrderError_Line3": "Jeżeli problem nadal występuje, zadzwoń do nas {tel} (pon.‑pt. w godz. 8:00‑16:00).",
    "Cart_OrderError_Line4": "Jeśli infolinia jest nieczynna, {contactUs}.\n",
    "Cart_OrderNow": "Zamawiam teraz",
    "Cart_ReOrderAlert_Error_Text_AllProducts": "Przykro nam, produkty z Twojego poprzedniego zamówienia ({products}) są w tym momencie niedostępne \uD83D\uDE22\n",
    "Cart_ReOrderAlert_Error_Text_PartOfProducts": "Przykro nam, co najmniej jeden produkt z Twojego poprzedniego zamówienia ({products}) jest w tym momencie niedostępny \uD83D\uDE22\n",
    "Cart_ReOrderAlert_Error_Text_SingleProduct": "Przykro nam, produkt z Twojego poprzedniego zamówienia ({product}) jest w tym momencie niedostępny \uD83D\uDE22\n",
    "Cart_ReOrderAlert_Error_Title_NotSingleProduct": "Nie udało się dodać produktów do koszyka",
    "Cart_ReOrderAlert_Error_Title_SingleProduct": "Nie udało się dodać produktu do koszyka",
    "Cart_ReOrderAlert_IconClose_Alt": "zamknij",
    "Cart_RetryPayment": "Ponów płatność",
    "Cart_ShippingCost": "Koszt wysyłki",
    "Cart_ShippingCost_Free": "Gratis",
    "Cart_ShippingTime": "Czas dostawy",
    "Cart_ShippingTime_Value": "Za 1-3 dni u Ciebie",
    "Cart_ShippingTime_ValueShort": "1-3 dni",
    "Cart_SomeProductAreUnavailable": "Brak niektórych produktów",
    "CartStaticPromo_Text_1_1": "Bawełniany ręcznik do włosów",
    "CartStaticPromo_Text_1_2": "Cotton Hair Wrap",
    "CartStaticPromo_Text_1_3": "",
    "CartStaticPromo_Text_2_1": "",
    "CartStaticPromo_Text_2_2": "",
    "CartStaticPromo_Text_2_3": "",
    "CartStaticPromo_Title": "Dodaj za 1 gr:",
    "Cart_Subtotal": "Suma",
    "CartSummary_CareClub_Coinback_DescriptionForNotLoggedIn": "Jesteś niezalogowana. O ostatecznej wartości przyznanego smilesbacku poinformujemy Cię  w mailu z potwierdzeniem zamówienia.\n",
    "Cart_Summary_CareClub_CoinsUsed": "Rabat lekko Club",
    "Cart_Summary_Count": "{count} szt.",
    "Cart_Summary_Title": "Podsumowanie zamówienia",
    "Cart_Summary_TitleComplete": "Twoje zamówienie {order}",
    "Cart_Summary_YourOrder": "Twoje zamówienie",
    "Cart_Title": "Twój koszyk",
    "Cart_Total": "Suma zamówienia",
    "Cart_Upsell_Add": "Dodaj",
    "Cart_Upsell_Add_Price": "{add} za {price}\n",
    "Cart_Upsell_CareClubOnly": "Tylko w {strong}",
    "Cart_Upsell_CareClubOnly_Strong": "lekko Club",
    "Cart_Upsell_Subtitle": "Dbaj o siebie kompleksowo. Czuj się {strong}!\n",
    "Cart_Upsell_Subtitle_Strong": "lekko\n",
    "Cart_Upsell_Title": "Może dodasz?",
    "Checkout_Address_AddNewAddress": "Dodaj nowy adres",
    "Checkout_Address_Duplicate": "W książce adresowej jest już identyczny adres.",
    "Checkout_Address_EditAddress": "Edytuj adres",
    "Checkout_Address_Invoice": "Faktura VAT",
    "Checkout_Address_SaveAddress": "Zapisz adres",
    "Checkout_Address_Tel_Prefix": "Tel.",
    "Checkout_CardForm_Field_CVV": "CVC/CVV",
    "Checkout_CardForm_Field_Date": "Data ważności",
    "Checkout_CardForm_Field_Date_Placeholder": "MM/RR",
    "Checkout_CardForm_Field_Legal": "Zlecenie realizacji płatności wykonuje PayU SA. Płacąc akceptujesz {link}.",
    "Checkout_CardForm_Field_Legal_Link": "Zasady płatności PayU",
    "Checkout_CardForm_Field_Number": "Numer karty",
    "Checkout_CardForm_Field_Pin": "Kod PIN",
    "Checkout_CardForm_Field_SaveCard": "Chcę zapisać dane karty na potrzeby przyszłych płatności. Usługa PayUExpress świadczona zgodnie z {link}.",
    "Checkout_CardForm_Field_SaveCard_Link": "regulaminem Usługi",
    "Checkout_CardForm_ValidationError_CardEmpty": "Pole wymagane",
    "Checkout_CardForm_ValidationError_CardLength": "Za krótki numer karty",
    "Checkout_CardForm_ValidationError_CardNumber": "Niepoprawny numer karty",
    "Checkout_CardForm_ValidationError_CardUnsupported": "Nie obsługujemy kart tego typu",
    "Checkout_CardForm_ValidationError_CvvEmpty": "Pole wymagane",
    "Checkout_CardForm_ValidationError_CvvValue": "Niepoprawna wartość",
    "Checkout_CardForm_ValidationError_ExpDateEmpty": "Pole wymagane",
    "Checkout_CardForm_ValidationError_ExpDatePast": "Termin ważności karty musi być w przyszłości",
    "Checkout_CardForm_ValidationError_ExpDateValue": "Wprowadź poprawną datę w formacie MM/RR",
    "Checkout_CardModal_State_3DS": "Potwierdź płatność",
    "Checkout_CardModal_State_CheckingPayment_Text": "Twoja płatność jest właśnie przetwarzana przez PayU, może to potrwać nawet kilka minut.",
    "Checkout_CardModal_State_CheckingPayment_Title": "Sprawdzamy status płatności",
    "Checkout_CardModal_State_CVV": "Wpisz kod CVV",
    "Checkout_CardModal_State_Error_Text": "Płatność nie powiodła się. Sprawdź stan konta lub limity na karcie.",
    "Checkout_CardModal_State_Error_Title": "Płatność odrzucona",
    "Checkout_CardModal_State_MakingPayment_Text": "Wysyłamy zlecenie płatności do PayU",
    "Checkout_CardModal_State_MakingPayment_Title": "Procesujemy Twoją płatność",
    "Checkout_CardModal_State_UpdateCard_Text": "Sprawdzamy czy podane dane karty są poprawne.",
    "Checkout_CardModal_State_UpdateCard_Title": "Sprawdzamy Twoją kartę",
    "Checkout_CardModal_Title": "Płatność kartą",
    "Checkout_Complete_CareClub_Button": "sprawdzam",
    "Checkout_Complete_CareClub_Button_InCareClub": "sprawdź swój status",
    "Checkout_Complete_CareClub_Customer_Text": "smiles za dzisiejsze zamówienie będą aktywne na Twoim koncie po upływie  21 dni od daty doręczenia zamówienia. {link}\n",
    "Checkout_Complete_CareClub_Customer_Text_Link": "Sprawdź swój status w klubie.",
    "Checkout_Complete_CareClub_Customer_Text_Strong": "",
    "Checkout_Complete_CareClub_Customer_Text_Strong2": "",
    "Checkout_Complete_CareClub_Customer_Title": "Otrzymujesz {coins} smiles!",
    "Checkout_Complete_CareClub_Question": "Co musisz zrobić, żeby z nich skorzystać?",
    "Checkout_Complete_CareClub_Text": "Zbieraj smiles i wymieniaj na rabaty, {strong}. Im większe Twoje zaangażowanie, tym więcej korzyści.\n",
    "Checkout_Complete_CareClub_Text2": "Za to zamówienie otrzymałaś {strong}, sprawdź co musisz zrobić, żeby z nich skorzystać.\n",
    "Checkout_Complete_CareClub_Text2_Strong": "smiles",
    "Checkout_Complete_CareClub_Text_Strong": "nawet do -50%",
    "Checkout_Complete_CareClub_Title": "lekko club",
    "Checkout_Complete_CareClub_Title_CareClub": "",
    "Checkout_Complete_Footer_Link_Blog": "✨ Poczytaj {link}",
    "Checkout_Complete_Footer_Link_Blog_LinkText": "naszego bloga",
    "Checkout_Complete_Footer_Link_Social": "\uD83D\uDD75 Znajdź nas na {instagram} i {facebook}",
    "Checkout_Complete_Footer_Link_Social_Facebook": "facebooku",
    "Checkout_Complete_Footer_Link_Social_Instagram": "instagramie",
    "Checkout_Complete_Footer_Text": "Dbanie o siebie nie musi być ciężką pracą – już zaraz się o tym przekonasz. A dziś dołącz do miejsc, gdzie dzielimy się wiedzą:\n",
    "Checkout_Complete_Footer_Title": "Hej, zostańmy w kontakcie",
    "Checkout_Complete_MGM_Text": "Daj bliskiej osobie 20% OFF, a dostaniesz 20% smilesbacku! {strong} i podziel się 20% rabatem na pierwsze zakupy.\n",
    "Checkout_Complete_MGM_Text_Strong": "Udostępnij swój link bliskim",
    "Checkout_Complete_OrderSummary": "Podsumowanie zamówienia o numerze {orderId} oraz informacje dotyczące zmiany statusu otrzymasz od nas mailowo na adres {email}.\n",
    "Checkout_Complete_PageTitle": "zamówienie przyjęte",
    "Checkout_Complete_Registration_Advantages_1": "szybsze przejście przez proces zakupowy",
    "Checkout_Complete_Registration_Advantages_2": "możliwość sprawdzenia statusu zamówienia",
    "Checkout_Complete_Registration_Advantages_3": "przeglądanie historii zamówień",
    "Checkout_Complete_Registration_Advantages_4": "korzystanie z wielu adresów dostawy",
    "Checkout_Complete_Registration_Text": "Załóż konto jednym kliknięciem - wystarczy, że potwierdzisz swój adres e-mail. Możesz zyskać:\n",
    "Checkout_Complete_Registration_Text_Error": "Napotkaliśmy błąd. \uD83D\uDE22 Spróbuj ponownie.",
    "Checkout_Complete_Registration_Text_Success": "Udało się! Na Twoj adres e-mail wysłaliśmy wiadomość z potwierdzeniem założenia konta.  Wystarczy, że ustawisz hasło klikając w link w wiadomości i gotowe! \uD83C\uDF89\n",
    "Checkout_Complete_Registration_Title": "Chcesz kupować wygodniej?",
    "Checkout_Complete_Subtitle": "cieszy nas, że sięgasz po inteligenty self-care. Od teraz take it easy, take it lekko! ❤️",
    "Checkout_Complete_Title": "Zamówienie złożone",
    "Checkout_Complete_Upsell_GoBack": "Wróć do sklepu",
    "Checkout_Complete_Upsell_Info": "Podsumowanie zamówienia oraz informacje dotyczące zmiany statusu otrzymasz od nas mailowo na adres {email}.\n",
    "Checkout_Complete_Upsell_OrderSummary": "Uzupełniliśmy Twoje zamówienie o numerze  {orderId}.",
    "Checkout_Complete_Upsell_PageTitle": "zamówienie uzupełnione",
    "Checkout_Complete_Upsell_Subtitle": "{name}, dziękujemy za stworzenie zgranej paczki!",
    "Checkout_Complete_Upsell_Title": "Zamówienie uzupełnione",
    "Checkout_Contact_Bok_Hours": "Pon. - Pt. 8:00 - 16:00",
    "Checkout_Contact_Bok_Text": "Potrzebujesz pomocy?\nZadzwoń do nas.\n",
    "Checkout_Data_BillingInfo": "Dane rozliczeniowe",
    "Checkout_Data_CreateAccount_Info_Text": "Link do potwierdzenia rejestracji konta otrzymasz po złożeniu zamówienia.",
    "Checkout_Data_CreateAccount_Info_Title": "Możesz teraz kontynuować zakupy.",
    "Checkout_Data_CreateAccount_Subtitle": "szybsze zakupy w przyszłości",
    "Checkout_Data_CreateAccount_Title": "Chcę założyć konto",
    "Checkout_Data_Edit": "Edytuj",
    "Checkout_Data_GuestText": "Bez konta nie będziesz mogła sprawdzić statusu zamówienia ani przejść w przyszłości szybciej przez proces zakupowy.",
    "Checkout_Data_GuestTitle": "Kupujesz jako gość",
    "Checkout_Data_HaveAnAccount_ForgotPassword": "Nie pamiętam hasła",
    "Checkout_Data_HaveAnAccount_Info": "Wygląda na to, że masz już konto w naszym sklepie. Podaj hasło i zaloguj się żeby uzupełnić dane do wysyłki:",
    "Checkout_Data_HaveAnAccount_Question": "Masz już konto?",
    "Checkout_Data_HiCustomer": "Cześć {name}",
    "Checkout_Data_LoggedAs": "Jesteś zalogowany jako {customer}",
    "Checkout_Data_Newsletter_Primary": "Chcę zapisać się do newslettera",
    "Checkout_Data_Newsletter_Secondary": "najlepsze oferty promocyjne i porady ekspertów na email",
    "Checkout_Data_ShippingInfo": "Dane do wysyłki",
    "Checkout_Data_Title": "Podaj swoje dane",
    "Checkout_GoToPayment": "Realizuj zamówienie",
    "Checkout_GoToShipping": "Realizuj zamówienie",
    "Checkout_LoggedUser_Disclaimer": "Sprawdź poniższe dane i zapłać.",
    "Checkout_LoggedUser_Email": "Jesteś zalogowany jako {email}.",
    "Checkout_LoggedUser_Hello": "Cześć {name}",
    "Checkout_MaxValueInfo_GoToCart": "Przejdź do koszyka",
    "Checkout_MaxValueInfo_Line1": "W związku ze zmianami wynikającymi z Brexit, maksymalna kwota pojedynczego zamówienia wysyłanego  do Wielkiej Brytanii wynosi {maxValue}.  Przepraszamy za niedogodności,  pracujemy nad umożliwieniem składania większych zamówień.",
    "Checkout_MaxValueInfo_Line2": "Aby zrealizować zamówienie, usuń produkty z koszyka tak, aby kwota zamówienia nie przekraczała {maxValue}",
    "Checkout_MaxValueInfo_Title": "Maksymalna wartość zamówienia to {maxValue}",
    "Checkout_Payment_Failed_Contact": "Jeżeli potrzebujesz pomocy ze złożeniem zamówienia, zadzwoń do nas: {phone} (Pon. ‑ Pt. w godz. 8:00‑16:00). Jeśli infolinia jest nieczynna, {contactUs}\n",
    "Checkout_Payment_Failed_Phone": "+48 799 354 054 ",
    "Checkout_Payment_Failed_Text": "Może to być spowodowane brakiem środków na rachunku, błędnym uwierzytelnieniem lub problemami technicznymi bramki płatności. Środki nie zostały pobrane.",
    "Checkout_Payment_Failed_Title": "Płatność nie powiodła się.",
    "Checkout_Payment_Failed_TryAgain": "Spróbuj ponownie",
    "Checkout_PaymentMethod_CardModal_CardAddConfirm": "Dodaj kartę",
    "Checkout_PaymentMethod_CardModal_CvvConfirm": "Zatwierdź",
    "Checkout_PaymentMethod_CardModal_CVVError": "Nie udało się potwierdzić karty",
    "Checkout_PaymentMethod_CardModal_NewCard": "Dodaj nową kartę",
    "Checkout_PaymentMethod_CardModal_NewCardFormUnavailable": "Wprowadzanie danych karty chwilowo niedostępne. Spróbuj wybrać inną metodę płatności.",
    "Checkout_PaymentMethod_CardModal_PayWithCard": "Zapłać kartą",
    "Checkout_PaymentMethod_CardModal_SavedCards": "Twoje zapisane karty",
    "Checkout_PaymentMethod_CardModal_Title": "Zapłać kartą",
    "Checkout_PaymentMethod_CardModal_TokenizeError": "Nie udało się dodać karty",
    "Checkout_PaymentMethod_CardToken_Prefix": "Zapisana karta",
    "Checkout_PaymentMethod_CashOnDelivery": "Za przesyłkę zapłacisz kurierowi przy odbiorze.",
    "Checkout_PaymentMethod_ChooseBank_Title": "Wybierz metodę płatności",
    "Checkout_PaymentMethod_Free": "Zamówienie nie wymaga płatności.",
    "Checkout_PaymentMethod_Title": "Metoda płatności",
    "Checkout_PaymentMethod_Voucher_Add": "Dodaj kartę upominkową",
    "Checkout_PaymentMethod_Voucher_Info": "",
    "Checkout_PaymentMethod_Voucher_Label": "Karta upominkowa",
    "Checkout_PaymentMethod_Voucher_Modal_Button": "Dodaj kartę",
    "Checkout_PaymentMethod_Voucher_Modal_Info": "Wprowadź dane karty upominkowej - znajdziesz je w wiadomości e-mail. Twoja karta łączy się ze wszystkimi metodami płatności.",
    "Checkout_PaymentMethod_Voucher_NotActive": "",
    "Checkout_PaymentMethod_Voucher_Remove": "Usuń",
    "Checkout_PaymentMethod_Voucher_Status_Exceeds": "Wartość Twojej karty upominkowej jest większa niż suma do zapłacenia - cała kwota zamówienia zostanie pokryta kartą. Resztę salda ({amount}) będziesz mogła wykorzystać dodając kartę ponownie przy kolejnych zakupach.",
    "Checkout_PaymentMethod_Voucher_Status_Expired": "Dodana przez Ciebie karta upominkowa wygasła. Usuń ją by kontynuować zamówienie.",
    "Checkout_PaymentMethod_Voucher_Status_Info": "Płatność kartą upominkową za inną kartę upominkową jest niemożliwa, dlatego z Twojej karty pobierzemy {amount} na pozostałą część zamówienia. Resztę salda ({difference}) będziesz mogła wykorzystać dodając kartę ponownie przy kolejnych zakupach.",
    "Checkout_PaymentMethod_Voucher_Value": "",
    "Checkout_Payment_WaitingForConfirmation": "Czekamy na potwierdzenie Twojej płatności",
    "Checkout_PayULegalInfo_Line1": "Płacąc akceptujesz {link}.",
    "Checkout_PayULegalInfo_Line1_Link": "zasady płatności PayU",
    "Checkout_PayULegalInfo_Line2": "Administratorem danych osobowych jest Lekko Life z siedzibą w Warszawie dane osobowe będą przetwarzane w celu realizacji Zamówienia i o ile wyrazisz na to zgodę w celach marketingowych.  W Polityce Prywatności Administrator informuje o celu, okresie i podstawach prawnych przetwarzania danych osobowych, a także o prawach jakie przysługują osobom, których dane osobowe dotyczą, odbiorców danych osobowych oraz profilowaniu.\n",
    "Checkout_PayU_LegalInfo_URL": "https://poland.payu.com/nota-o-prywatnosci-payu/",
    "Checkout_ReOrder_Loading": "Sprawdzamy Twoje ostatnie zamówienie",
    "Checkout_ReOrder_Title": "Lekko",
    "Checkout_Shipping_Method": "Sposób dostawy",
    "Checkout_Shipping_Method_Info": "90% zamówień przekazujemy kurierowi w ciągu 24h.",
    "Checkout_Shipping_Method_Winter_Message": "Ze względu na bardzo niskie temperatury rekomendujemy wybór innej formy dostawy (np. do stacjonarnego punktu odbioru). Istnieje ryzyko zamarznięcia produktu w paczkomacie znajdującym się na zewnątrz.",
    "Checkout_Shipping_Unavailable": "Adres nieobsługiwany",
    "Checkout_Shipping_Unavailable_ChangeStore": "wybierz inną wersję językową sklepu",
    "Checkout_Shipping_Unavailable_Text": "Wysyłka do Twojego kraju z polskiej strony jest niemożliwa. Zmień adres lub {changeStore}. Pamiętaj, że twój koszyk będzie musiał zostać skompletowany od początku.\n",
    "Checkout_Steps_Data": "Dane",
    "Checkout_Steps_Payment": "Płatność",
    "Checkout_Steps_Shipping": "Dostawa",
    "Checkout_Steps_Sumup": "Podsumowanie",
    "Common_AddAllToCart": "Dodaj wszystkie do koszyka",
    "Common_AddedToCart": "Dodano do koszyka",
    "Common_AddToCart": "Dodaj do koszyka",
    "Common_AddToCart_ExternalLink": "Przejdź do zakupu",
    "Common_AddToCartShort_Error": "Błąd",
    "Common_AddToCartShort_Success": "Dodano",
    "Common_AddToCartShort_Text": "Kup",
    "Common_AddToCartWithValue": "Dodaj do koszyka za {value}",
    "Common_Back": "Powrót",
    "Common_BackToCareClub": "Wróć do lekko club",
    "Common_Cancel": "Anuluj",
    "Common_Cart": "Koszyk",
    "Common_Change": "Zmień",
    "Common_Close": "Zamknij",
    "Common_ConfirmAll": "Zaznacz wszystkie",
    "Common_Country_Cz": "Czechy",
    "Common_Country_De": "Niemcy",
    "Common_Country_Other": "inny",
    "Common_Country_Pl": "Polska",
    "Common_Country_Ro": "Rumunia",
    "Common_Country_Sk": "Słowacja",
    "Common_Country_Ua": "Ukraina",
    "Common_Country_UK": "Wielka Brytania",
    "Common_Days": "0 dni | 1 dzień | {n} dni | {n} dni",
    "Common_Days_Shortest": "d",
    "Common_GoToMainPage": "Przejdź do strony głównej",
    "Common_Hours": "0 godzin | 1 godzina | {n} godziny | {n} godzin",
    "Common_Hours_Shortest": "h",
    "Common_Login": "Zaloguj się",
    "Common_MainPage": "Strona główna",
    "Common_Minutes": "0 minut | 1 minuta | {n} minuty | {n} minut",
    "Common_Minutes_Short": "min.",
    "Common_Minutes_Shortest": "m",
    "Common_More": "Więcej",
    "Common_Novelty": "Nowość",
    "Common_Or": "Lub",
    "Common_Products": "Produkty",
    "Common_Promotion": "Promocja",
    "Common_ReadMore": "Czytaj więcej",
    "Common_Register": "Załóż konto",
    "Common_Seconds_Short": "sek.",
    "Common_Seconds_Shortest": "s",
    "Common_ShowProductBundle_Long": "Poznaj zestaw",
    "Common_ShowProduct_Long": "Poznaj produkt",
    "Common_ShowProduct_Short": "Poznaj",
    "Common_TemporarilyUnavailable": "Chwilowo niedostępny",
    "Common_TreatmentLength": "{days} dni stosowania",
    "Common_Years": "0 lat | 1 rok | {n} lata | {n} lat",
    "Contact_Breadcrumb": "Kontakt",
    "Contact_ContactUs_Call_Us": "Zadzwoń do nas",
    "Contact_ContactUs_Company_Address": "ul. Franciszka Klimczaka 1 Segment E, 02-797 Warszawa\nNIP: 9662131622 — KRS: 0000986637\n",
    "Contact_ContactUs_Company_Details": "Dane Spółki",
    "Contact_ContactUs_Company_Name": "Lekko Life\n",
    "Contact_ContactUs_Cooperation_Distribution": "Współpraca & Dystrybucja",
    "Contact_ContactUs_Cooperation_Distribution_Contact": "Jeśli masz pomysł na współpracę lub dystrybucję\nnaszych produktów — zapraszamy do kontaktu.\n",
    "Contact_ContactUs_Email_Address": "m.cieplik{'@'}healthlabs.pl",
    "Contact_ContactUs_Phone_Hours": "Infolinia czynna: Pon. - Pt. 8:00 - 16:00\nCena za połączenie wg stawek operatora.\n",
    "ContactForm_Button": "Wyślij",
    "ContactForm_ChooseTopic": "Wybierz temat",
    "ContactForm_Email": "E-mail",
    "ContactForm_FirstName": "Imię",
    "ContactForm_Message": "Treść wiadomości…",
    "ContactForm_Text": "Masz pytania dotyczące produktu, dostawy,\npłatności lub statusu swojego zamówienia?\n",
    "ContactForm_Text_Submitted": "Pracuje nad nią nasz zespół obsługi klienta, wkrótce otrzymasz odpowiedź.",
    "ContactForm_Title": "Napisz do nas",
    "ContactForm_Title_Submitted": "Dziękujemy za Twoją wiadomość!",
    "ContactForm_Topic_pk1": "Nowe zamówienie",
    "ContactForm_Topic_pk2a": "Informacja dotycząca istniejącego zamówienia / Informacja dotycząca statusu",
    "ContactForm_Topic_pk2b": "Informacja dotycząca istniejącego zamówienia / Problem z płatnością rabatem",
    "ContactForm_Topic_pk2c": "Informacja dotycząca istniejącego zamówienia / Zmiana danych kontaktowych",
    "ContactForm_Topic_pk3": "Reklamacja",
    "ContactForm_Topic_pk4": "Anulacja",
    "ContactForm_Topic_pk5": "Zwrot konsumencki",
    "ContactForm_Topic_pk6": "Zapytanie merytoryczne",
    "ContactForm_Topic_pk7": "Oferta marketingowa",
    "ContactForm_Topic_pk8": "Zgłoś naruszenie",
    "Cooperation_ContactUs_CallUs": "Infolinia",
    "Cooperation_ContactUs_Company_Address": "ul. Franciszka Klimczaka  1 Segment E, 02-797 Warszawa NIP: 9662131622  —  KRS: 0000851383\n",
    "Cooperation_ContactUs_Company_Details": "Dane spółki",
    "Cooperation_ContactUs_Company_Name": "Lekko Life",
    "Cooperation_ContactUs_Cooperation_Distribution": "Współpraca ambasadorska",
    "Cooperation_ContactUs_Cooperation_DistributionContact": "Jeśli masz pomysł na współpracę lub dystrybucję naszych produktów — zapraszamy do {link}.\n",
    "Cooperation_ContactUs_Cooperation_DistributionContact_Link": "kontaktu",
    "Cooperation_ContactUs_Cooperation_WithUs": "Zostań naszym ambasadorem",
    "Cooperation_ContactUs_Email": "ambasadorzy{'@'}healthlabs.pl",
    "Cooperation_ContactUs_Phone": "+48 799 354 054",
    "Cooperation_ContactUs_PhoneHours": "Infolinia czynna: Pon. - Pt. 8:00 - 16:00 Cena za połączenie wg. stawek operatora.\n",
    "Cooperation_ContactUs_Title": "Skontaktuj się z nami",
    "CooperationForm_Country": "",
    "CooperationForm_Description": "",
    "CooperationForm_Email": "",
    "CooperationForm_FirstName": "",
    "CooperationForm_HelperText": "Administratorem danych osobowych jest LEKKO LIFE z siedzibą w Warszawie. Twoje dane osobowe będą przetwarzane w celach związanych z obsługą zgłoszenia. Masz prawo żądania dostępu do danych, ich sprostowania, usunięcia, przenoszenia danych lub ograniczenia ich przetwarzania, prawo do sprzeciwu oraz złożenia skargi do Prezesa Urzędu Ochrony Danych Osobowych. Pełna treść informacji jest dostępna w {link}\n",
    "CooperationForm_HelperText_Link": "Klauzuli informacyjnej RODO",
    "CooperationForm_HelperText_Link_Url": "https://prismic-io.s3.amazonaws.com/healthlabs/6ec95ddd-2a22-4790-b8ef-7cb466c65d8b_KLAUZULA+INFORMACYJNA+RODO+AMBASADOR+MARKI.pdf",
    "CooperationForm_InfoText": "Zastrzegamy, że odpowiemy jedynie na wybrane zgłoszenia.",
    "CooperationForm_InstagramFollowers": "",
    "CooperationForm_InstagramUserName": "",
    "CooperationForm_Label_CooperationRadio": "Czy aktualnie współpracujesz z inną marką suplementów diety?",
    "CooperationForm_Label_CooperationRadio_False": "nie",
    "CooperationForm_Label_CooperationRadio_True": "tak",
    "CooperationForm_Label_Country": "Kraj prowadzenia działalności:",
    "CooperationForm_Label_Email": "E-mail:",
    "CooperationForm_Label_Facebook": "Facebook (nick lub link):",
    "CooperationForm_Label_FirstName": "Imię:",
    "CooperationForm_Label_Instagram": "Instagram (nick lub link):",
    "CooperationForm_Label_LastName": "Nazwisko:",
    "CooperationForm_Label_Mobile": "Nr telefonu:",
    "CooperationForm_Label_OtherSocialMedia": "Inne kanały (blog, podcast, etc.):",
    "CooperationForm_Label_RulesCheckbox": "Wyrażam zgodę na przetwarzanie przez Lekko Life Sp. z o. o. z siedzibą w Warszawie ul. Franciszka Klimczaka 1 Segment E, 02-797 Warszawa powyżej wskazanych moich danych osobowych  w celu rozpatrzenia mojego zgłoszenia dotyczącego chęci wzięcia udziału w programie ambasador i umożliwienia kontaktu ze mną.\n",
    "CooperationForm_Label_TikTok": "TikTok (nick lub link):",
    "CooperationForm_Label_Twitter": "Twitter (nick lub link):",
    "CooperationForm_Label_YouTube": "YouTube (nick lub link):",
    "CooperationForm_LastName": "",
    "CooperationForm_Phone": "",
    "Cooperation_Form_Subitle": "",
    "Cooperation_Form_Subtitle": "",
    "CooperationForm_Text_Submitted": "Uprzejmie informujemy, że skontaktujemy się wyłącznie z wybranymi osobami.\n",
    "Cooperation_Form_Title": "Napisz do nas",
    "CooperationForm_Title_Submit": "wyślij zgłoszenie",
    "CooperationForm_Title_Submitted": "Dziękujemy",
    "Coupon_Add": "Dodaj",
    "Coupon_AddCouponCode": "Dodaj kod rabatowy",
    "Coupon_CouponCode": "Kod rabatowy",
    "Coupon_Error_CountDown": "Kod {couponName} będzie aktywny za {countdownText}.",
    "Coupon_Error_CountDown_Activating": "Aktywujemy kod {couponName}...",
    "Coupon_Error_CountDown_Default": "Kod {couponName} jeszcze nie jest aktywny.",
    "Coupon_Error_CouponExpired": "Kod jest nieaktywny",
    "Coupon_Error_CouponNotFound": "Podany kod jest nieprawidłowy",
    "Coupon_Error_ExternalRuleForActivationCoupon": "{couponText}, żeby aktywować kod rabatowy {couponName}.",
    "Coupon_ErrorModal_IconClose_Alt": "Zamknij",
    "Coupon_ErrorModal_IconError_Alt": "Nie udało się dodać kodu do koszyka.",
    "Coupon_ErrorModal_Title": "Nie udało się dodać kodu do koszyka.",
    "Coupon_Error_UserAction": "{couponText}, żeby aktywować kod rabatowy {couponName}.",
    "Coupon_Error_UserAction_Default": "Dodaj odpowiednie produkty do koszyka, żeby aktywować kod rabatowy {couponName}.",
    "Coupon_NoCode": "Wpisz kod",
    "Coupon_Promo_Error_Ended": "Promocja już się zakończyła",
    "Coupon_Promo_Error_MissingProducts": "Aby skorzystać z kodu dodaj do koszyka produkty objęte promocją",
    "Coupon_Promo_Error_NotStarted": "Promocja jeszcze się nie rozpoczęła",
    "Coupon_Promo_Error_SpecialChars": "Kod rabatowy może składać się tylko z liter i cyfr",
    "CustomPromo_Cart_BeforeSteps_Text": "Brakuje Ci  jeszcze tylko {left}, aby uzyskać zniżkę {percent}. \uD83C\uDF89 Dobierz swoje ulubione  produkty już teraz!\n",
    "CustomPromo_Cart_BeforeSteps_TextStrong": "Możesz oszczędzić",
    "CustomPromo_Cart_BeforeSteps_Title": "Rabat do {percent} {span} {coupon}",
    "CustomPromo_Cart_BeforeSteps_TitleSpan": "z kodem",
    "CustomPromo_Cart_EmptyCoupon_Text": "Użyj kodu {coupon}, aby uzyskać rabat nawet do {percent}! \uD83C\uDF89 Nie czekaj!\n",
    "CustomPromo_Cart_EmptyCoupon_TextStrong": "Możesz oszczędzić",
    "CustomPromo_Cart_EmptyCoupon_Title": "Rabat do {percent} {span} {coupon}",
    "CustomPromo_Cart_EmptyCoupon_TitleSpan": "z kodem",
    "CustomPromo_Cart_FirstStep_Text": "Większe zakupy - wyższy rabat! Korzystasz z rabatu {currentPercent} Brakuje  Ci {left} do {percent}, {span},  a nawet więcej! \uD83C\uDF89 Dodaj kolejne produkty już teraz!\n",
    "CustomPromo_Cart_FirstStep_TextStrong": "Możesz oszczędzić",
    "CustomPromo_Cart_FirstStep_Title": "Rabat do {percent} {span} {coupon}",
    "CustomPromo_Cart_FirstStep_TitleSpan": "z kodem",
    "CustomPromo_Cart_SecondStep_Text": "Korzystasz z {percent} rabatu, największego w naszej historii! \uD83C\uDF89 Uzupełnij swoje zapasy i ciesz się wymarzonymi rezultatami.\n",
    "CustomPromo_Cart_SecondStep_Title": "Zasługujesz na więcej!",
    "CustomPromo_Home_Countdown": "jeszcze przez {countdown}",
    "CustomPromo_Home_Countdown_NotStarted": "już za {countdown}",
    "CustomPromo_Home_Coupon": "Użyj kodu:  {coupon}",
    "CustomPromo_Home_Cta": "kup teraz",
    "CustomPromo_Home_Step1_TextWithoutValue": "{percent} na WSZYSTKO",
    "CustomPromo_Home_Step1_TextWithValue": "{percent} przy zakupach od {value} PLN",
    "CustomPromo_Home_Step2_TextWithValue": "{percent} przy zakupach od {value} PLN",
    "CustomPromo_ProductDetails_Step1_TextWithoutValue": "{percent} na wszystko",
    "CustomPromo_ProductDetails_Step1_TextWithValue": "{percent} od {value} PLN",
    "CustomPromo_ProductDetails_Step2_TextWithValue": "{percent} od {value} PLN",
    "CustomPromo_ProductDetails_Title": "{bold} Użyj kodu: {coupon}",
    "CustomPromo_ProductDetails_Title_Strong": "Takiej promocji jeszcze nie było!",
    "Error_400_Title": "Ups... coś poszło nie tak. Spróbuj ponownie za kilka chwil.",
    "Error_404_Code": "Błąd tzw. 404",
    "Error_404_Text": "Wygląda na to, że strona, której szukasz nie istnieje lub została przeniesiona \uD83D\uDE22",
    "Error_404_Title": "Ups, pusto",
    "Error_500_Code": "Wystąpił nieznany błąd",
    "Error_500_Text": "Hej! Właśnie pracujemy nad usprawnieniami na naszej stronie. Już niedługo wszystko powinno znowu działać \uD83D\uDC9C\n",
    "Error_500_Title": "Ups, error",
    "Error_BackToMainPage": "odwiedź sklep",
    "FaqSlice_ShowLinkToFaqPage_Text": "Odpowiedzi znajdziesz na stronie {link}",
    "FaqSlice_ShowLinkToFaqPage_Text_Link": "FAQ",
    "FaqSlice_ShowLinkToFaqPage_Title": "Masz więcej pytań?",
    "Footer_Company_ManageCookies": "Zarządzaj cookies",
    "Footer_Company_Name": "Lekko Life Sp. z o.o. z siedzibą w Warszawie przy\nul. Franciszka Klimczaka 1, 02-797 Warszawa\nKRS: 0000915337 — REGON: 389658235 — NIP: 5423438367",
    "Footer_Company_Title": "Sklep",
    "Footer_ConvenientDelivery_Subtitle": "Kurierzy, paczkomaty\ni punkty odbioru\n",
    "Footer_ConvenientDelivery_Title": "Wygodna\ndostawa\n",
    "Footer_Copyrights": "Wszelkie prawa zastrzeżone.",
    "Footer_FastDelivery_Subtitle": "Dla zamówień\nzłożonych do 12:00\n",
    "Footer_FastDelivery_Title": "Wysyłamy\nw ciągu 24h\n",
    "Footer_FreeShipping_Subtitle": "Dla zamówień\npowyżej {amount}\n",
    "Footer_FreeShipping_Title": "Darmowa\nwysyłka\n",
    "Footer_Help_Hours": "Pon. - Pt. 8:00 - 16:00\nCena za połączenie wg stawek operatora.\n",
    "Footer_Help_Messenger": "Napisz na Messengerze",
    "Footer_Help_SendEmail": "Wyślij wiadomość",
    "Footer_Help_Title": "Pomoc",
    "Footer_Intro_Text": "{company} to produkty, które pomagają\nzadbać  o wygląd,  odporność i dobre samopoczucie.\n",
    "Footer_Logo": "Logo Lekko",
    "Footer_Products_Title": "Produkty",
    "Footer_SafePayments_Subtitle": "Dzięki certyfikatowi\ni szyfrowaniu SSL\n",
    "Footer_SafePayments_Title": "Bezpieczne\npłatności\n",
    "ForgotPassword_EmailNotExists_1": "Podany email nie jest powiązany",
    "ForgotPassword_EmailNotExists_2": "z żadnym kontem w naszym sklepie.",
    "ForgotPassword_EmailNotExists_3": "Sprawdź poprawność wpisanego adresu lub załóż nowe konto.",
    "ForgotPassword_EmailSent": "Wysłaliśmy na Twój adres e-mail link do resetowania hasła.",
    "ForgotPassword_NewAccount": "Załóż nowe konto",
    "ForgotPassword_Text": "Podaj swój adres e-mail poniżej.  Otrzymasz wiadomość z linkiem do zresetowania hasła.",
    "ForgotPassword_Title": "Odzyskaj hasło",
    "Form_Address": "Ulica i nr mieszkania",
    "Form_City": "Miasto",
    "Form_Country": "Kraj",
    "Form_CustomerNotes": "Uwagi do zamówienia",
    "Form_Email": "Adres e-mail",
    "Form_Email_Short": "E-mail",
    "Form_Email_Suggestion": "Masz na myśli {suggestion}",
    "Form_FirstName": "Imię",
    "Form_Invoice": "Chcę otrzymać fakturę VAT",
    "Form_Invoice_CompanyName": "Nazwa firmy",
    "Form_Invoice_CompanyTaxId": "NIP",
    "Form_LastName": "Nazwisko",
    "Form_Optional": "Opcjonalnie",
    "Form_OtherShippingAddress": "Chcę podać inne dane dostawy",
    "Form_Password": "Hasło",
    "Form_Phone": "Numer telefonu",
    "Form_PostCode": "Kod pocztowy",
    "Form_RequiredFields": "pola wymagane",
    "Form_ShowPassword": "Pokaż hasło",
    "Form_Subscribe_Email": "e-mail",
    "Gift_Product_Form_Input_Email": "E-mail do przesłania karty",
    "Gift_Product_Form_Input_Message": "Twoje wsparcie w kilku słowach",
    "Gift_Product_Form_Input_Message_Placeholder": "Ty wiesz najlepiej, czego Ci trzeba. Zrealizuj wszystko! Z troską, przyjaciółka.",
    "Gift_Product_Form_Input_Name": "Imię osoby, której wysyłasz kartę",
    "Gift_Product_Form_Text": "Podaruj kilka słów od serca prosto na swoją kartę upominkową. Jeśli brakuje Ci słów to zostaw poniższe, nie zapomnij się {strong}",
    "Gift_Product_Form_Text_Strong": "podpisać",
    "Gift_Product_Form_Title": "Chodź {strong}",
    "Gift_Product_Form_Title_Strong": "na słówko!",
    "Gift_Product_Personalization_Button": "Personalizuj",
    "Globals_ContactEmail": "kontakt{'@'}lekko.pl",
    "Globals_ContactPhone": "+48 799 354 054",
    "Globals_ContactPhone_Second": "+48 799 354 054",
    "Globals_FacebookProfile": "lekkopl",
    "Globals_InstagramProfile": "lekko_pl",
    "Globals_LinkedinProfile": "health-labs-care",
    "Globals_PinterestProfile": "healthlabs_pl",
    "Globals_TiktokProfile": "healthlabs_pl",
    "Globals_WhatsApp": "+48799354054",
    "Globals_ZendeskRequestEndpoint": "https://lekko.zendesk.com/api/v2/requests.json",
    "Globals_ZendeskTopicFieldId": "19357691286802",
    "Globals_HomeLink": "lekko",
    "Header_Logo": "Logo Lekko",
    "Header_Menu": "Menu",
    "Language_Czech": "czeski",
    "Language_Polish": "polski",
    "Language_Romanian": "rumuński",
    "LiveNow_Counter_Connect": "Oglądam",
    "LiveNow_Counter_Days": "DNI",
    "LiveNow_Counter_Hours": "GODZ.",
    "LiveNow_Counter_Minutes": "MIN.",
    "LiveNow_Counter_Seconds": "SEK.",
    "LiveNow_Counter_Title": "Startujemy już za:",
    "LiveNow_Counter_Title_Last": "Ostatni live",
    "LiveNow_Counter_Title_Next": "Najbliższy live",
    "LiveNow_Counter_Watch": "Dołączam",
    "LocaleWarnModal_Change_cz": "Wybierz stronę czeską",
    "LocaleWarnModal_Change_pl": "",
    "LocaleWarnModal_Continue_cz": "Kontynuuj",
    "LocaleWarnModal_Continue_pl": "",
    "LocaleWarnModal_Text_cz": "Czy na pewno chcesz kontynuować czy wolisz przejść na stronę czeską?",
    "LocaleWarnModal_Text_pl": "",
    "LocaleWarnModal_Title_cz": "Pamiętaj!",
    "LocaleWarnModal_Title_pl": "",
    "LocaleWarnModal_Warn_Bold_cz": "jest niemożliwa.",
    "LocaleWarnModal_Warn_Bold_pl": "",
    "LocaleWarnModal_Warn_cz": "Wysyłka do Czech z polskiego sklepu {bold}",
    "LocaleWarnModal_Warn_pl": "",
    "Login_Reason_Text": "Twoje dane zostały zaktualizowane.",
    "Login_Reason_Title": "Zaloguj się ponownie.",
    "Login_Email_Change_Text": "Zmiana maila dokona się po potwierdzeniu.",
    "Login_Email_Change_Title": "Email z potwierdzeniem został do Ciebie wysłany.",
    "Login_Password_Change_Text": "Zaloguj się ponownie.",
    "Login_Password_Change_Title": "Twoje dane zostały zaktualizowane.",
    "Login_RecoverPassword": "Przypomnij hasło",
    "Login_Register_Button": "Zarejestruj się",
    "Login_Register_Text": "Zarejestruj się w Lekko aby otrzymać dostęp do swoich zamówień, śledzić przesyłki i otrzymywać oferty specjalne.",
    "Login_Register_Title": "Nie masz konta?",
    "Login_Title": "Logowanie",
    "MainMenu_Item_Hi": "Hej, {name}!",
    "MainMenu_Item_ReOrder": "Zamów ponownie",
    "MainMenu_Submenu_ReOrder": "Twoje ostatnie zamówienie",
    "MainPage_Categories_ProductsLong": "Poznaj produkty",
    "MainPage_Categories_ProductsShort": "Produkty",
    "MainPage_Categories_Title": "Wybierz to, czego potrzebujesz",
    "MainPage_Instagram_Subtitle": "Porady żywieniowe naszych ekspertów, ciekawostki związane z funkcjonowaniem organizmu oraz nowinki z zakresu dietetyki poznasz śledząc nasz profil.",
    "MainPage_Instagram_Title": "Bądź z nami {strong}",
    "MainPage_Instagram_Title_Strong": "w kontakcie",
    "ManagePermissions_Agreement_Nl": "Zgoda na komunikację marketingową za pomocą email",
    "ManagePermissions_Agreement_Phone": "Zgoda na komunikację marketingową za pomocą telefonu",
    "ManagePermissions_Agreement_Processing": "Zgoda na przetwarzanie danych osobowych w celach marketingowych",
    "ManagePermissions_Agreement_Processing_Text": "Pamiętaj, że wyłącznie tej zgody uniemożliwi nam kierowanie do Ciebie komunikacji telefonicznie, mailowo i smsowo",
    "ManagePermissions_Agreement_Sms": "Zgoda na komunikację marketingową za pomocą sms",
    "ManagePermissions_Error_Subtitle": "Wystąpił błąd",
    "ManagePermissions_Error_Text": "Wystąpił błąd podczas ładowania listy zgód.\nSpróbuj odświeżyć stronę.\n",
    "ManagePermissions_Legal_Info": "Administratorem danych osobowych jest Lekko Life z siedzibą  w Warszawie [kontakt e mail {email}, tel. {phone}]  (Administrator). Administrator w Polityce Prywatności  informuje o celu, okresie i podstawach prawnych przetwarzania  danych osobowych, a także o prawach jakie przysługują osobom,  których przetwarzane dane osobowe dotyczą, podmiotom  którym Administrator może powierzyć do przetwarzania  dane osobowe, oraz o zasadach zautomatyzowanego przetwarzania  danych osobowych, w tym ich profilowaniu.\n",
    "ManagePermissions_Modal_Accept": "Potwierdzam rezygnację",
    "ManagePermissions_Modal_Bold1": "wypis z członkostwa w lekko club.",
    "ManagePermissions_Modal_Bold2": "Poziom 1.",
    "ManagePermissions_Modal_Newsletter": "Rezygnacja ze Zgody na komunikację marketingową za pomocą email spowoduje {bold1} Ponowne zaznaczenie Zgody przywróci Cię na {bold2}",
    "ManagePermissions_Modal_Phone": "Rezygnacja ze Zgody na komunikację marketingową za pomocą telefonu spowoduje {bold1} Ponowne zaznaczenie Zgody przywróci Cię na {bold2}",
    "ManagePermissions_Modal_Processing": "Rezygnacja ze Zgody na przetwarzanie danych osobowych spowoduje {bold1} Ponowne zaznaczenie Zgody przywróci Cię na {bold2}",
    "ManagePermissions_Modal_Remember": "Pamiętaj!",
    "ManagePermissions_Modal_Sms": "Rezygnacja ze Zgody na komunikację marketingową za pomocą sms spowoduje {bold1} Ponowne zaznaczenie Zgody przywróci Cię na {bold2}",
    "ManagePermissions_Status_Change_Disable": "Zrezygnuj",
    "ManagePermissions_Status_Change_Enable": "Aktywuj",
    "ManagePermissions_Status_Disabled": "Wyłączona",
    "ManagePermissions_Status_Enabled": "Aktywna",
    "ManagePermissions_Subtitle": "Wszystkie zgody muszą być aktywne jeżeli chcesz korzystać z benefitów jakie oferuje Ci lekko club.\n",
    "ManagePermissions_Title": "Zgody marketingowe",
    "MeetSet_Product": "Poznaj lepiej",
    "MeetSet_Title": "Co ma",
    "Mgm_Conditions_For_Connect_LoggedIn_Join": "dołączam",
    "Mgm_Conditions_For_Connect_LoggedIn_Join_Text": "Na warunkach wskazanych w Załączniku nr 2 do Regulaminu Sklepu - Regulamin Programu lekko club.\n",
    "Mgm_Conditions_For_Connect_LoggedIn_Register": "załóż konto",
    "Mgm_Conditions_For_Connect_LoggedIn_Text": "Co musisz zrobić, żeby skorzystać ze swoich smiles?",
    "Mgm_Dashboard_Conditions": "Dołącz do programu i zatroszcz się o swoich najbliższych. Podaruj 20% rabatu na pierwsze zakupy Twoich bliskich i zyskaj 20% smilesbacku.",
    "Mgm_Dashboard_Conditions_Logged": "Co musisz zrobić, żeby zdobyć rabat za polecania?",
    "Mgm_Dashboard_Conditions_NotLogged": "W lekko club lub zyskujesz więcej. Dołącz do programu i podziel się 20% rabatem na pierwsze zakupy Twoich bliskich.",
    "Mgm_Dashboard_Error_MGMID": "{strong} Odśwież stronę lub skontaktuj się z nami pod numerem telefonu: {phone}",
    "Mgm_Dashboard_Error_MGMID_Strong": "Ups, nie udało się wygenerować linka.",
    "Mgm_Dashboard_MGMID_Placeholder": "Tu pojawi się Twój link",
    "Mgm_Dashboard_Title": "Daj bliskiej osobie 20% OFF a dostaniesz 20% {strong}!",
    "Mgm_Dashboard_Title_Strong": "smilesback",
    "Mgm_Dashboard_Title_Highlighted": "Friend zone! \n",
    "Mgm_Dashboard_TitleLoggedWithCC": "Podaruj bliskiej osobie 20% rabatu i zyskaj 20% {strong}!",
    "Mgm_Dashboard_TitleLoggedWithCC_Strong": "smilesback",
    "Mgm_Dashboard_TitleLoggedWithoutCC": "",
    "Mgm_Dashboard_TitleNotLogged": "",
    "Mgm_Discount_Link_Share_Info": "Kod zniżkowy zadziała tylko, jeśli osoba, która z niego korzysta zweryfikuje swój numer telefonu. Kod automatycznie dodamy do koszyka i prześlemy mailem.",
    "Mgm_Discount_Link_Share_Info2": "Jednorazowy kod zniżkowy -20% dodamy do koszyka automatycznie oraz prześlemy w wiadomości e-mail.",
    "Mgm_Discount_Link_Share_Link": " i daj im 20% OFF na pierwsze zakupy.",
    "Mgm_Discount_Link_Share_Link_Bold": "Prześlij link swoim friends",
    "Mgm_Discount_Link_Tooltip": "Skopiowano link",
    "Mgm_Form_InvitationAgreement": "",
    "Mgm_Form_Send": "",
    "Mgm_Form_Text": "",
    "Mgm_Recommend_Button_Get_Discount": "odbierz rabat",
    "Mgm_Recommend_Failure_Button": "ZAPROŚ FRIENDS, MIEJ ZNIŻKĘ",
    "Mgm_Recommend_Failure_Text_Bold1": "",
    "Mgm_Recommend_Failure_Text_Bold2": "",
    "Mgm_Recommend_Failure_Text_Bold_Already_Client": "Wygląda na to, że twój kod od znajomego został wykorzystany.\n",
    "Mgm_Recommend_Failure_Text_Bold_Expire": "Wygląda na to, że twój kod od znajomego wygasł.",
    "Mgm_Recommend_Failure_Text_Bold_Used": "Wygląda na to, że Twój kod polecający został już wykorzystany.",
    "Mgm_Recommend_Failure_Text_Normal": " Nic straconego. Ty też możesz korzystać ze zniżek, ale inaczej.\u2028Zaproś swoich friends, daj im 20% OFF i otrzymaj zniżkę.\n",
    "Mgm_Recommend_Failure_Text_Normal1": "",
    "Mgm_Recommend_Failure_Text_Normal2": "",
    "Mgm_Recommend_Failure_Text_Normal_Already_Client": "Kod rabatowy dla ciebie został wygenerowany i użyty przy zamówieniu {order}\n",
    "Mgm_Recommend_Failure_Text_Normal_Expire": "Twój indywidualny kod rabatowy został wygenerowany {startDate}, a jego ważność wynosiła {days} dni.",
    "Mgm_Recommend_Failure_Text_Normal_Used": "Twój indywidualny kod rabatowy został wygenerowany {endDate} i wykorzystany przy zamówieniu {orderNumber}\n",
    "Mgm_Recommend_Header": "{name} przesyła ci {strong} na twoje pierwsze zakupy!",
    "Mgm_Recommend_Header_Strong": "20% OFF",
    "Mgm_Recommend_Header_Condition1": "Jesteś tu bo twój friend cię zaprosił. Cute. Zweryfikuj numer telefonu i zyskajcie razem!",
    "Mgm_Recommend_Header_Condition2": "- 20% OFF na całe zakupy",
    "Mgm_Recommend_Header_Condition2_Highlighted": "Ty",
    "Mgm_Recommend_Header_Condition3": "- prezent w postaci smiles o wartości 20% kwoty twojego zamówienia",
    "Mgm_Recommend_Header_Condition3_Highlighted": "{name}",
    "Mgm_Recommend_Success_Text_1": "To jednorazowy kod {coupon} na pierwsze zakupy w lekko. ",
    "Mgm_Recommend_Success_Text_2": " Będzie ważny przez {days} dni! I już czeka twoim koszyku - nie musisz go nigdzie wpisywać. \n",
    "Mgm_Recommend_Success_Text_3": "PS. Kos wysłaliśmy też na twojego maila",
    "Mgm_Recommend_Success_Text_Bold1": "No i jest! \n",
    "Mgm_Recommend_Success_Text_Bold2": "Twój zupełnie nowy kod zniżkowy.",
    "Mgm_Recommend_Verify_Phone_Form_Button": "Zweryfikuj numer",
    "Mgm_Recommend_Verify_Phone_Form_Input_Label": "Twój kod",
    "Mgm_Recommend_Verify_Phone_Form_Title": "Zweryfikuj swój numer telefonu",
    "Mgm_Recommend_Veryfy_Phone_Modal_No_Message": "Nie widzisz wiadomości?",
    "Mgm_Recommend_Veryfy_Phone_Modal_Send_Again": "Wyślij kod ponownie",
    "Mgm_Recommend_Veryfy_Phone_Modal_Title": "Wpisz 6-cyfrowy kod z wiadomości SMS.",
    "Mgm_Recommend_Veryfy_Phone_Modal_Verify": "zweryfikuj",
    "Mgm_Summary_Recommend_Awaiting_Coinback": "czekasz na {strong}",
    "Mgm_Summary_Recommend_Awaiting_Coinback_Strong": "smilesback",
    "Mgm_Summary_Recommend_Check_Recommendations": "Sprawdź swoje polecenia",
    "Mgm_Summary_Recommend_Deadline": "ważne przez",
    "Mgm_Summary_Recommend_Deadline_Days": "dni",
    "Mgm_Summary_Recommend_No_Recommendations": "Tutaj pojawią się Twoje polecenia",
    "Mgm_Summary_Recommend_Order_Placeed": "jest zamówienie, yay!",
    "Mgm_Summary_Recommend_Recommendation": "polecenie",
    "Mgm_Summary_Recommend_Recommendation_Expired": "wygasło",
    "Mgm_Summary_Recommend_Recommendation_Failed": "nieudane",
    "Mgm_Summary_Recommend_Recommendation_Pending": "zaproszenie accepted",
    "Mgm_Summary_Recommend_Recommendation_Successful": "polecenie zakończone",
    "Mgm_Summary_Recommend_Show_All": "+ pokaż wszystkie",
    "Mgm_Summary_Recommend_Status_Text": "Co oznaczają poniższe statusy?",
    "Mgm_Summary_Recommend_Your_Recommendations": "Twój friend zone",
    "Month_1": "Styczeń",
    "Month_10": "Październik",
    "Month_11": "Listopad",
    "Month_12": "Grudzień",
    "Month_2": "Luty",
    "Month_3": "Marzec",
    "Month_4": "Kwiecień",
    "Month_5": "Maj",
    "Month_6": "Czerwiec",
    "Month_7": "Lipiec",
    "Month_8": "Sierpień",
    "Month_9": "Wrzesień",
    "Navigation_AboutUs": "O nas",
    "Navigation_Account": "Konto",
    "Navigation_Account_Long": "Twoje konto",
    "Navigation_AllProducts": "Wszystkie produkty",
    "Navigation_Blog": "Blog",
    "Navigation_LekkoClub_LekkoClub": "Lekko Club",
    "Navigation_CareClub_CareCoinsMobile": "Masz",
    "Navigation_CareClub_CareCoinsShort": "LC",
    "Navigation_CareClub_Coinback": "Korzystasz z {coinback} {strong}",
    "Navigation_CareClub_Coinback_Strong": "smilesback",
    "Navigation_CareClub_GetDiscount": "i odbierz zniżkę na kolejne zamówienie",
    "Navigation_CareClub_JoinToCareClub": "dołącz do lekko club",
    "Navigation_CareClub_Novelty": "nowość",
    "Navigation_ContactUs": "Napisz do nas",
    "Navigation_Dietitian": "Dietetyk Online",
    "Navigation_Live": "transmisje live",
    "Navigation_Mgm": "Polecaj",
    "Navigation_ProductReviews": "Opinie",
    "Navigation_Search": "Szukaj",
    "Navigation_YourAccount": "twoje konto",
    "Newsletter": "Newsletter",
    "Newsletter_Description": "Zapisz się i otrzymuj {bold} oferty i zyskaj dostęp do porad naszych ekspertów. {bold2}",
    "Newsletter_Description_Bold1": "lekkie",
    "Newsletter_Description_Bold2": "Bo po co ciężko, jak można lekko?",
    "Newsletter_Disclaimer": "Zgadzam się na przetwarzanie moich danych osobowych przez Lekko Life Sp. z o. o. z siedzibą w Warszawie ul. Franciszka Klimczaka 1, w celu wysyłki na podane dane kontaktowe Newslettera  zawierającego treści marketingowe zgodnie z {link}.",
    "Newsletter_Disclaimer_Link": "Regulaminem",
    "Newsletter_Disclaimer_Text": "Administratorem danych osobowych jest Lekko Life Sp. z o.o. z siedzibą w Warszawie, dane osobowe będą przetwarzane w celu wysyłki Newslettera. Możesz cofnąć wyrażoną zgodę w każdym czasie bez wpływu na zgodność z prawem przetwarzania dokonanego przed ich wycofaniem. Masz prawo: dostępu do danych, ich sprostowania, usunięcia, ograniczenia przetwarzania, przenoszenia i sprzeciwu oraz złożenia skargi do Prezesa Urzędu Ochrony Danych Osobowych. {link} sprawdzisz jak przetwarzamy dane osobowe.",
    "Newsletter_Disclaimer_Text_Link": "TUTAJ",
    "Newsletter_Error_Button": "Spróbuj ponownie",
    "Newsletter_Error_Text": "Podczas dodawania Twojego adresu email do newslettera wystąpił błąd",
    "Newsletter_Error_Title": "Ups… wystąpił błąd",
    "Newsletter_Input_Placeholder": "E-mail",
    "Newsletter_Success_Text": "Sprawdź skrzynkę pocztową i potwierdź zapis do newslettera",
    "Newsletter_Success_Title": "Wysłaliśmy wiadomość email",
    "Newsletter_Text": "Zapisz się, otrzymuj {strong1} oferty\ni porady naszych ekspertów.\n{strong2}",
    "Newsletter_Text_Strong1": "lekkie",
    "Newsletter_Text_Strong2": "Bo po co ciężko, jak można lekko?",
    "Omnibus_Price_Modal_Current_Price": "Aktualna cena produktu",
    "Omnibus_Price_Modal_Lowest_Price": "Najniższa cena towaru w okresie 30 dni przed wprowadzeniem obniżki:",
    "Omnibus_Price_Modal_Promo_Lowest_Price": "Najniższa cena towaru w okresie 30 dni przed wprowadzeniem obniżki",
    "Omnibus_Price_Modal_Promo_Price": "Cena promocyjna",
    "Omnibus_Price_Modal_Promo_Price_Code": "Cena promocyjna z kodem",
    "Omnibus_Price_Modal_Regular_Price": "Cena regularna",
    "Omnibus_Price_Modal_Title": "Oznaczenia cen",
    "Pagination_Text": "{first} - {last} z {total}",
    "Payment_Blik_WithCode": "z kodem",
    "Payment_Blik_WithNoCode": "bez kodu",
    "Payment_CreditCard": "Karta płatnicza",
    "Payment_PayLater": "Płacę później",
    "Payment_PayU": "Przelewy online",
    "Payment_SafePayment": "Bezpieczna płatność dzięki szyfrowaniu SSL",
    "Poll_Additional_Recommendation_Cosmetic_Page_Description_Scenario_0": "Aby wszystkie składniki zawarte w kosmetykach mogły ze sobą jak najlepiej współpracować, najpierw postaw na dokładne oczyszczenie skóry używając kosmetyków z linii Pure On: Emulsji do demakijażu i oczyszczania lub Łagodnego żelu myjącego. A jeśli potrzebujesz dodatkowego ukojenia i nawilżenia, sięgnij po Mgiełkę nawilżająco-odświeżającą Pure On, którą możesz stosować nawet w ciągu dnia!",
    "Poll_Page_Button_Text": "Wypełniam ankietę",
    "Poll_Pending_Empty": "Czekamy na Twoje odpowiedzi!\nWypełnij ankietę i odkryj produkty dopasowane do Twoich potrzeb.\n",
    "Poll_Pending_Text": "Twoja rekomendacja jest prawie gotowa. A Ty?\nZa moment odkryjesz produkty, które rekomendujemy na podstawie Twoich odpowiedzi.\n",
    "Poll_Recommendations_Cosmetic_Page_1_Heading": "Jeśli chcesz uzupełnić swoją pielęgnację, dodaj do niej także:",
    "Poll_Recommendations_Cosmetic_Page_Description": "To kompleksowe połączenie produktów dopasowanych do aktualnych potrzeb Twojej skóry. Pomogą Ci zadbać o siebie od zewnątrz.",
    "Poll_Recommendations_Page_1_Heading": "Pamiętaj o podstawach suplementacji:",
    "Poll_Recommendations_Page_2_Heading": "Zadbaj o bazę suplementacyjną",
    "Poll_Recommendations_Page_Description_Scenario_0": "To produkty dopasowane do Twoich aktualnych potrzeb. Bezpieczne i kompleksowe połączenie składników rekomendowanych suplementów pomoże Ci zadbać o siebie od wewnątrz.",
    "Poll_Recommendations_Page_Description_Scenario_1": "To wegańskie produkty dopasowane do Twoich aktualnych potrzeb. Kompleksowe połączenie składników rekomendowanych suplementów diety pomoże Ci zadbać o siebie od wewnątrz.",
    "Poll_Recommendations_Page_Description_Scenario_10": "To kompleksowe połączenie produktów {strong}",
    "Poll_Recommendations_Page_Description_Scenario_10_Strong": "dopasowane do aktualnych potrzeb Twojego organizmu.",
    "Poll_Recommendations_Page_Description_Scenario_11": "To kompleksowe połączenie produktów {strong}",
    "Poll_Recommendations_Page_Description_Scenario_11_Strong": "dopasowane do aktualnych potrzeb Twojego organizmu.",
    "Poll_Recommendations_Page_Footer": "Wyniki ankiety i podpowiedzi dotyczące doboru suplementacji opierają się wyłącznie na odpowiedziach Użytkownika i mają wyłącznie charakter informacyjny oraz nie stanowią diagnozy, ani nie zastępują porady lekarskiej, farmaceutycznej lub medycznej. Jeżeli cierpisz na jakąś jednostkę chorobową  - skonsultuj się z lekarzem w celu uzyskania odpowiedzi na pytania dotyczące zdrowia i dobrego samopoczucia oraz  możliwości przyjmowania rekomendowanej suplementacji.",
    "Poll_Recommendations_Page_Headling": "",
    "Poll_Recommendations_Page_Headling1": "",
    "Poll_Recommendations_Page_Paragraph1": "",
    "Poll_Recommendations_Page_Title": "{name}, w trosce o Twoje potrzeby ",
    "Poll_Recommendations_Page_Title_Underline": "rekomendujemy:",
    "Poll_Recommendations_Page_Title_Welcome": "Hej",
    "Poll_Recommendations_Supplementation_Page_Description_Scenario_0": "Baza suplementacyjna, o którą warto wzbogacić swoją dietę to przede wszystkim produkt z kwasami Omega 3, które przyczyniają się do prawidłowego funkcjonowania mózgu i serca oraz witamina D3 wspierająca odporność, a także układ nerwowy i kostny.",
    "Poll_Recommendations_Supplementation_Page_Description_Scenario_0_Italic_1": "OmegaMe",
    "Poll_Recommendations_Supplementation_Page_Description_Scenario_0_Italic_2": "Vitamin D Natural+",
    "Poll_Recommendations_Supplementation_Page_Description_Scenario_10": "Ważne: suplement diety LittleMe zawiera żelazo w kapsułce DAY (dziennej, spożywanej rano). Stosowanie preparatów z żelazem przed 16 tygodniem ciąży jest zalecane w przypadku niedokrwistości z niedoboru żelaza. {strong}",
    "Poll_Recommendations_Supplementation_Page_Description_Scenario_10_Strong": "Porozmawiaj ze swoim lekarzem, by podjąć decyzję o suplementacji.",
    "Poll_Recommendations_Supplementation_Page_Description_Scenario_11": "Ważne: suplement diety LittleMe zawiera żelazo w kapsułce dziennej. Stosowanie preparatów z żelazem przed 16 tygodniem ciąży jest zalecane w przypadku niedokrwistości z niedoboru żelaza.",
    "Poll_Recommendations_Supplementation_Page_Description_Scenario_11_Strong": "Porozmawiaj ze swoim lekarzem, by podjąć decyzję o suplementacji.",
    "Poll_Recommendations_Supplementation_Page_Description_Scenario_1_Text_1": " Pamiętaj o bazie suplementacyjnej, o którą warto wzbogacić swoją dietę: {italic1} i {italic2}. OmegaMe Vege to produkt oparty wyłącznie na składnikach roślinnych - algach. Dostarcza niezbędnych kwasów tłuszczowych Omega 3 DHA z alg morskich z witaminą D3 , które przyczyniają się do utrzymania prawidłowego funkcjonowania mózgu i serca, a Witamina D wspiera odporność, a także układ nerwowy oraz kostny.",
    "Poll_Recommendations_Supplementation_Page_Description_Scenario_1_Text_1_Italic_1": "OmegaMe Vege",
    "Poll_Recommendations_Supplementation_Page_Description_Scenario_1_Text_1_Italic_2": "Vitamin D Natural+",
    "Poll_Recommendations_Supplementation_Page_Description_Scenario_1_Text_2": "Dieta roślinna wymaga szczególnej dbałości o podaż witamin i składników mineralnych. Suplement diety {italic} to kompleksowy i złożony produkt przeznaczony zwłaszcza dla osób będących na diecie wegańskiej i wegetariańskiej, które mają zwiększone zapotrzebowanie na witaminy z grupy B oraz składniki mineralne, w tym żelazo, selen czy wapń.",
    "Poll_Recommendations_Supplementation_Page_Description_Scenario_1_Text_2_Italic": "VegeMe",
    "Popup_ReOrder_Button": "ponów ostatnie zamówienie",
    "Popup_ReOrder_Text": "Regularne przyjmowanie suplementów to klucz do sukcesu - my wiemy, że produkty z Twojego ostatniego zamówienia mogą się już kończyć.\n",
    "Popup_ReOrder_Title": "Zachowaj ciągłość suplementacji",
    "ProductCard_BlogPost_Read": "Czytaj wpis",
    "ProductCard_Infobox_CareClub_Heading": "Oszczędzaj w lekko club",
    "ProductCard_Infobox_Delayed_Shipping": "Wysyłka",
    "ProductCard_Infobox_Delayed_Shipping_Strong": "po {date}",
    "ProductCard_Infobox_Fast_Shipping": "Szybka wysyłka {strong}",
    "ProductCard_Infobox_Fast_Shipping_Strong": "w 24h",
    "ProductCard_Infobox_Free_Delivery": "Darmowa dostawa",
    "ProductCard_Infobox_PayPo": "Kup teraz, zapłać {strong}",
    "ProductCard_Infobox_PayPo_Strong": "za 30 dni",
    "Product_CoinBack": "Dołącz do lekko club już teraz, a za zakup tego produktu  otrzymasz {coins}, które wymienisz na {money} rabatu przy kolejnych zakupach.  Jesteś juz w klubie? {link}!\n",
    "Product_CoinBack_CoinsText": "smiles",
    "Product_CoinBack_InCareClub": "Obecnie korzystasz z {percent}% smilesbacku, dlatego za ten produkt otrzymasz {coins}, które możesz wymienić na rabat przy kolejnych zakupach. {link}\n",
    "Product_CoinBack_InCareClub_LinkText": "Sprawdź swój status w klubie",
    "Product_CoinBack_LinkText": "Zaloguj się",
    "Product_Composition_ActiveIngredients": "Składniki aktywne",
    "Product_Composition_EnergyValue": "Wartość energetyczna",
    "Product_Composition_FullComposition": "Tabela składników aktywnych",
    "Product_Composition_GDA": "{percent}% RWS*",
    "ProductDetails_CareClubOnlyButton": "Tylko w lekko club",
    "ProductDetails_CareClubOnlyTag_FirstLine": "Tylko w",
    "ProductDetails_CareClubOnlyTag_SecondLine": "lekko club",
    "ProductDetails_Upsell_CareClubOnly": "Tylko w {strong}",
    "ProductDetails_Upsell_CareClubOnly_Strong": "lekko club",
    "ProductDetails_Upsell_Cosmetic_Text": "{name} idealnie komponuje się z:",
    "ProductDetails_Upsell_Cosmetic_Title": "Stwórz kompletną pielęgnację",
    "ProductDetails_Upsell_Product_AddToCart": "{strong} za {price} {currency}",
    "ProductDetails_Upsell_Product_AddToCart_Strong": "Dodaj",
    "ProductDetails_Upsell_Supplement_Text": "Aby dodatkowo wesprzeć swój organizm, połącz {name} z produktami, które idealnie pasują:\n",
    "ProductDetails_Upsell_Supplement_Title": "",
    "Product_Dosing_Info": "Nie należy przekraczać zalecanej dziennej porcji. Zrównoważony sposób żywienia i prawidłowy tryb życia jest ważny dla funkcjonowania organizmu człowieka. Suplement diety nie może być stosowany jako substytut (zamiennik) zróżnicowanej diety.",
    "Product_Dosing_Title": "Zalecane spożycie",
    "Product_Dosing_Title_Cosmetic": "Sposób użycia",
    "Product_Faq_Title": "Najczęściej zadawane pytania",
    "Product_HelpSlice_Details_CallToUs": "Zadzwoń do nas:",
    "Product_HelpSlice_Details_CallToUs_Small": "Cena za połączenie wg stawek operatora.",
    "Product_HelpSlice_Details_TalkToUs": "Porozmawiaj z nami",
    "Product_HelpSlice_Details_TalkToUs_Messenger": "na Messengerze",
    "Product_HelpSlice_Details_Text": "Nasz zespół wsparcia klienta odpowiada na różne pytania od poniedziałku do piątku {strong}",
    "Product_HelpSlice_Details_Text_Strong": "od 8:00 do 16:00.",
    "Product_HelpSlice_Details_WriteToUs": "Wyślij wiadomość:",
    "Product_LastViewed_Title": "Ostatnio przeglądane produkty",
    "ProductList_Aside_Btn": "Brak produktów | Pokaż 1 produkt | Pokaż {n} produkty | Pokaż {n} produktów",
    "ProductList_Aside_CategoriesTitle": "Czego szukasz?",
    "ProductList_ProductsQuantity": "0 produktów | 1 produkt | {n} produkty | {n} produktów",
    "ProductList_PromoHeader_Countdown": "jeszcze przez {countdown}",
    "ProductList_PromoHeader_Coupon": "z kodem {coupon}",
    "ProductLoginModal_ButtonHelper": "Dołącz do lekko club",
    "ProductLoginModal_Subtitle": "Jesteś w klubie? Zaloguj się by móc kupić ten produkt.",
    "ProductLoginModal_Text": "Nie jesteś w lekko club? Dołącz, by móc kupić ten produkt, korzystać ze zniżek na zakupy nawet do 50%, obniżonych progów darmowej dostawy i innych benefitów.",
    "ProductLoginModal_Title": "Produkt dostępny jest tylko dla członków lekko club.",
    "Product_PayPo_Text": "Kup {strong1}, zapłać za {strong2}",
    "Product_PayPo_Text_Strong1": "teraz",
    "Product_PayPo_Text_Strong2": "30 dni",
    "Product_Pricing_BundleSavings": "W zestawie {strong}",
    "Product_Pricing_BundleSavings_Strong": "oszczędzasz {money}!",
    "Product_Pricing_Promo": "Promocja!",
    "Product_Pricing_Striked": "z kodem",
    "ProductReview_ReviewsQuantity": " opinii |  opinia |  opinie |  opinii",
    "Product_Reviews_GoToReviews": "Pokaż opinie o produkcie",
    "ProductReviews_OriginalLang": "Tekst oryginalny: {lang}.",
    "ProductReviews_Response_Author": "Lekko",
    "ProductReviewsSearch_Filter": "Filtruj",
    "ProductReviewsSearch_FiltersAll": "Wszystkie produkty",
    "ProductReviewsSearch_NoResults_Clear": "Zobacz wszystkie opinie",
    "ProductReviewsSearch_NoResults_Text": "Niestety nie znaleźliśmy nic dla szukanej frazy.",
    "ProductReviewsSearch_NoResults_Title": "Brak wyników",
    "ProductReviewsSearch_Product_Count": "na podstawie {strong}",
    "ProductReviewsSearch_Product_Count_Strong": "opinii",
    "ProductReviewsSearch_Product_ShowOnlyNotEmptyComment": "Tylko opinie z komentarzem",
    "ProductReviewsSearch_Product_Sort": "Sortowanie",
    "ProductReviewsSearch_ShowOnlyNotEmptyComment": "Pokaż tylko opinie z komentarzem",
    "ProductReviewsSearch_Sort": "Sortuj",
    "ProductReviewsSearch_Sort_Best": "Od najlepszej",
    "ProductReviewsSearch_Sort_Newest": "Od najnowszej",
    "ProductReviewsSearch_Sort_Oldest": "Od najstarszej",
    "ProductReviewsSearch_Sort_Worst": "Od najgorszej",
    "Product_Reviews_Title": "Poznaj opinie {italic}",
    "Product_Reviews_Title_Italic": "naszych klientów",
    "ProductReviews_VerifiedBuyer": "Zweryfikowana kupująca",
    "ProductReviews_WantShare": "Poleciłabym ten produkt znajomej.",
    "Products_EmptyState_Bold": "",
    "Products_EmptyState_Text": "Usuń wybrane filtry, aby rozszerzyć wyszukiwanie, albo {bold}",
    "Products_EmptyState_Text_Bold": "zobacz wszystkie produkty.",
    "Products_EmptyState_Title": "Niestety nie znaleźliśmy tego, co szukasz \uD83D\uDE22",
    "Products_Filters_ClearAll": "wyczyść wszystkie",
    "Products_Filters_ClearFilters": "Wyczyść",
    "Products_Filters_Filter": "Filtruj",
    "Products_Filters_Indications": "Wskazania",
    "Products_Filters_ProductForm": "Rodzaj produktu",
    "Products_Filters_ProductLine": "Linia produktów",
    "Products_Filters_Purpose": "",
    "Products_Filters_RemoveFilter": "",
    "Products_Filters_SkinNeeds": "Potrzeby skóry",
    "Products_Filters_Title": "Filtry",
    "Product_ShippingInfo_Free": "Darmowa wysyłka",
    "Product_ShippingInfo_FreeFrom": "Darmowa dostawa od {amount}",
    "Product_ShippingInfo_Time": "Wysyłka w {time}",
    "Product_ShippingInfo_Time_Value": "24h",
    "ProductsList_CategoryInfo_HideMore": "",
    "ProductsList_CategoryInfo_ShowMore": "",
    "ProductsList_SliceInfo_HideMore": "Ukryj",
    "ProductsList_SliceInfo_ShowMore": "Czytaj dalej",
    "Products_Title": "Nasze produkty",
    "Product_SubscribeNotifyModal_Button": "Zapisz",
    "Product_SubscribeNotifyModal_Button_Success": "Zapisano",
    "Product_SubscribeNotifyModal_CheckboxTitle": "Zapisz się na nasz Newsletter, aby otrzymywać informacje o nowościach i atrakcyjne treści\n",
    "Product_SubscribeNotifyModal_Disclaimer": "Chcę otrzymać powiadomienie, kiedy produkt znów będzie dostępny w sprzedaży.",
    "Product_SubscribeNotifyModal_DisclaimerNewsletter": "Dodatkowo chcę otrzymywać newsletter od Lekko zawierający treści marketingowe na podany przeze mnie adres e-mail.",
    "Product_SubscribeNotifyModal_Error_Button": "Spróbuj ponownie",
    "Product_SubscribeNotifyModal_Error_Text": "Coś poszło nie tak i nie udało nam się zapisać Twojego adresu e-mail",
    "Product_SubscribeNotifyModal_Error_Title": "Ups Wystąpił błąd",
    "Product_SubscribeNotifyModal_InputTitle": "Wpisz adres e-mail aby otrzymać informację, gdy produkt będzie ponownie dostępny.",
    "Product_SubscribeNotifyModal_Legal": "Administratorem Danych Osobowych jest Lekko Life Sp. z o.o. z siedzibą w Warszawie, dane osobowe będą przetwarzane w celu wysłania powiadomienia o dostępności produktu oraz wysyłki newslettera, jeżeli potwierdziłaś jego subskrypcję - na podstawie naszego prawnie uzasadnionego interesu. Więcej przeczytaj {link}.",
    "Product_SubscribeNotifyModal_Legal_Link": "tutaj",
    "Product_SubscribeNotifyModal_Success_Button": "Zamknij",
    "Product_SubscribeNotifyModal_Success_Text": "Napiszemy do Ciebie, kiedy ten produkt będzie znów dostępny w sprzedaży.",
    "Product_SubscribeNotifyModal_Success_TextNewsletter": "Sprawdź skrzynkę pocztową i potwierdź zapis do newslettera.",
    "Product_SubscribeNotifyModal_Success_Title": "Udało się!",
    "Product_SubscribeNotifyModal_Title": "Powiadom o dostępności",
    "Product_SubscribeProductNotifyEmail": "Powiadom mnie o dostępności",
    "Product_SubscribeProductNotifyEmail_Short": "Powiadom",
    "ProductTag_CareClubOnly": "Tylko w lekko club",
    "ProductTile_BundleSavings": "W zestawie oszczędzasz {saving}",
    "Product_Title_Bundle": "Zestaw suplementów diety",
    "Product_Title_Single": "Suplement diety",
    "Product_Title_Type_Medical": "Żywność specjalnego przeznaczenia medycznego",
    "Product_Unavailable": "Produkt jest niedostępny",
    "Product_Variant_Default_Title": "Wybierz smak",
    "Promo_Button": "Sprawdź",
    "QuantityPicker_Decrease": "Zmniejsz ilość",
    "QuantityPicker_Increase": "Zwiększ ilość",
    "Rating_Page_Button_Text_Shop": "Odwiedź sklep",
    "Rating_Page_Button_Text_Skip": "Oceń później",
    "Rating_Page_Button_Text_Submit": "Wystaw opinię",
    "Rating_Page_Empty_Description": "Wygląda na to, że wszystkie produkty zostały już ocenione. Dziękujemy!",
    "Rating_Page_Empty_Header": "Pusto!",
    "Rating_Page_Form_Label_Age": "Wiek",
    "Rating_Page_Form_Label_Comment": "Komentarz",
    "Rating_Page_Form_Label_Name": "Imię",
    "Rating_Page_Form_Label_Share": "Tak, polecam ten produkt znajomym.",
    "Rating_Page_Form_Placeholder_Age": "Podaj tylko, jeśli masz ochotę :)",
    "Rating_Page_Form_Placeholder_Comment": "Powiedz trochę więcej o swoich wrażeniach, związanych z tym produktem…",
    "Rating_Page_Greeting_Description": "Dziękujemy, że wybierasz nasze produkty! Podzielisz się swoją opinią z innymi? Twoje zdanie jest dla nas bardzo ważne.",
    "Rating_Page_Greeting_Emoji": "✌️",
    "Rating_Page_Greeting_Hi": "Cześć",
    "Rating_Page_Header": "Jak oceniasz ten produkt?",
    "Rating_Page_Mgm_Button_Text_Shop": "Polecaj produkty",
    "Rating_Page_Mgm_Thx_Description": "Czy wiesz, że za polecanie produktów możesz otrzymać nawet 20% wartości zamówienia Twoich znajomych?",
    "Rating_Page_Thx_Description": "Twoja opinia pomoże innym podjąć decyzję!",
    "Rating_Page_Thx_Header": "Dziękujemy",
    "Rating_Page_Title": "Podziel się z nami opinią",
    "Rating_Page_Your_Rating": "Twoja ocena",
    "Rating_Products": "0 opinii | 1 opinia | {n} opinie | {n} opinii",
    "Register_AlreadyExists": "Konto o podanym adresie email już istnieje",
    "Register_BackToLogin": "Powrót do logowania",
    "Register_Button": "Załóż konto",
    "Register_ErrorOccured": "Wystąpił błąd, spróbuj ponownie później",
    "Register_Success_Text": "Twoje konto zostało pomyślnie założone.  Jesteś teraz zalogowany jako {email}. Wysłaliśmy maila z linkiem do ustawienia hasła.",
    "Register_Success_Title": "Dziękujemy za rejestrację!",
    "Register_Terms_CareClub": "Zaznacz wszystkie zgody aby dołączyć do {strong}, gdzie za każde zamówienie otrzymasz rabat na kolejne zakupy.\n",
    "Register_Terms_CareClub_Strong": "lekko club",
    "Register_Text": "Cieszymy się, że zdecydowałeś się założyć konto w Lekko. Uzupełnij poniższe pola, by utworzyć profil klienta.",
    "Register_Title": "Rejestracja",
    "ReOrderList_Description": "By czuć się {strong}, unikaj przerw w suplementacji. Regularne stosowanie to większe szanse na dobre rezultaty! A te produkty ostatnio były w twoim zamówieniu. \n",
    "ReOrderList_Description_Strong": "lekko\n",
    "ReOrderList_ShowAllProducts": "zobacz inne produkty",
    "ReOrderList_Title": "Zamawiasz znowu?",
    "ResetPassword_Cta": "Ustaw hasło",
    "ResetPassword_Error": "Link do resetu hasła wygasł lub jest nieprawidłowy",
    "ResetPassword_NewPassword": "Nowe hasło",
    "ResetPassword_Success": "Hasło zostało zmienione!",
    "ResetPassword_Title": "Zmień hasło",
    "Reviews_Carousel_Text": "To tylko niewielka część opinii o naszych produktach. Chcesz poznać więcej?",
    "Reviews_Carousel_Text_Cta": "Zobacz wszystkie",
    "Review_Status_Page_Header": "Ups... coś poszło nie tak",
    "Review_Status_Page_Try_Again": "Spróbuj ponownie",
    "Route_Live": "Transmisje live",
    "Search_Articles": "Artykuły",
    "Search_Articles_Newest": "Najnowsze artykuły",
    "Search_NoResults": "Brak wyników wyszukiwania",
    "Search_NoResults_Subtitle": "Poznaj nasze bestsellery",
    "Search_NoResults_Text": "Spróbuj przeformułować swoje zapytanie",
    "Search_Placeholder": "Szukaj rzeczy, które Cię interesują",
    "Search_Products_Upsell": "Polecane produkty",
    "Shipping_DHL_CashOnDelivery_Name": "Kurier DHL za pobraniem",
    "Shipping_DHL_Name": "Kurier DHL",
    "Shipping_DHL_PickUpPoint_Name": "Punkty i automaty DHL POP",
    "Shipping_DHL_PickUpPoint_Select": "Wybierz punkt odbioru",
    "Shipping_DPD_CashOnDelivery_Name": "Kurier DPD za pobraniem",
    "Shipping_DPD_Name": "Kurier DPD",
    "Shipping_DPD_PickUpPoint_Name": "DPD Pickup (punkt, automat paczkowy)",
    "Shipping_DPD_PickUpPoint_Select": "Wybierz punkt odbioru",
    "Shipping_DPD_Select": "Wybierz punkt odbioru",
    "Shipping_FxCouriers_Name": "FX Couriers",
    "Shipping_FxCouriers_NextDay_Label": "Dostawa w najbliższy dzień roboczy\nw godz. 18:00-22:00\n",
    "Shipping_FxCouriers_NextDay_Time": "Szybka dostawa",
    "Shipping_FxCouriers_SameDay_Label": "Zamów do 13:30\ni odbierz w godz. 18:00-22:00\n",
    "Shipping_FxCouriers_SameDay_Time": "Dziś u Ciebie",
    "Shipping_FxCouriers_SameDay_TimeShort": "1 dzień",
    "Shipping_Inpost_Name": "InPost Paczkomaty 24/7",
    "Shipping_Inpost_Point": "Paczkomat {name}",
    "Shipping_Inpost_Select": "Wybierz paczkomat",
    "Shipping_InPost_Time": "Za 1-2 dni u Ciebie",
    "Shipping_InPost_TimeShort": "1-2 dni",
    "Shipping_Pharmalink_Name": "Pharmalink (dostawa w temp. 2-8°C)",
    "Shipping_Pharmalink_Time": "Za 3-5 dni u Ciebie",
    "Shipping_Pharmalink_TimeShort": "3-5 dni",
    "Shipping_PickUpPoints_ChooseFromMap": "Wybierz z mapy",
    "Shipping_PickUpPoints_Closest": "Najbliżej Ciebie",
    "Shipping_PickUpPoints_NoPoints": "Nie znaleźliśmy punktów odbioru w pobliżu podanego adresu.",
    "Slices_Expert_TryProduct": "Wypróbuj {product}",
    "Social_Facebook_Alt": "Profil {profile} na Facebooku",
    "Social_Instagram_Alt": "Profil {profile} na Instagramie",
    "Social_Linkedin_Alt": "Profil {profile} na LinkedIn",
    "Social_Pinterest_Alt": "Profil {profile} na Pinterest",
    "Social_Tiktok_Alt": "Profil {profile} na TikTok",
    "Social_Whatsapp_Alt": "Czatuj w WhatsAppie z Healthlabs Care",
    "Terms_Accept_Outer": "Zapoznałem się i akceptuję {terms} i {privacy}",
    "Terms_Accept_Privacy": "Politykę Prywatności",
    "Terms_Accept_Terms": "Regulamin Sklepu",
    "Terms_Delivery_Breadcrumb_Key": "Metody dostawy",
    "Terms_Delivery_Foreign_Contries_Days": "2-5 dni",
    "Terms_Delivery_Page_Header_Above": "od",
    "Terms_Delivery_Page_Header_Column": "Sposób wysyłki",
    "Terms_Delivery_Page_Header_Column2": "Cena przesyłki przy zamówieniu:",
    "Terms_Delivery_Page_Header_Column3": "Czas dostawy",
    "Terms_Delivery_Page_Header_Column4": "(dni robocze)",
    "Terms_Delivery_Page_Header_Column5": "Dostarczamy do następujących krajów",
    "Terms_Delivery_Page_Header_Column6": "Cena przesyłki",
    "Terms_Delivery_Page_Header_LessThan": "poniżej",
    "Terms_Delivery_Page_Header_More": "",
    "Terms_Delivery_Page_Section1_Header": "Nasi partnerzy logistyczni",
    "Terms_Delivery_Page_Section4_Header": "Przesyłki zagraniczne",
    "Terms_Delivery_Page_Section4_Paragraph": "Dla zamówień dostarczanych poza Polskę:",
    "Terms_Delivery_Page_Section4_Paragraph2": "dostępna jest tylko dostawa kurierem DHL z przedpłatą - nie można wybrać dostawy za pobraniem",
    "Terms_Delivery_Page_Section4_Paragraph3": "darmowa dostawa obowiązuje dla zamówień od 600 PLN",
    "Terms_Delivery_Page_Section4_Paragraph4": "przy zamówieniu do Wielkiej Brytanii obowiązuje ograniczenie koszyka do 700 PLN",
    "Terms_Delivery_Page_ShippingTable_CoD": "za pobraniem",
    "Terms_Delivery_Page_ShippingTable_PrePayment": "przedpłata",
    "Terms_Delivery_Page_ShippingTable_Title": "Przesyłki krajowe",
    "Terms_Delivery_Page_ShippingTable_Title_Cz": "",
    "Terms_Delivery_Page_ShippingTable_Title_Svk": "",
    "Terms_Marketing_Checkout": "Chcę zapisać się do newslettera",
    "Terms_Marketing_Checkout_Info": "(top promocje, eksperckie tips i biohacking news tylko tam)",
    "Terms_Marketing_Contact": "",
    "Terms_Marketing_Contact_Email": "newsletter",
    "Terms_Marketing_Contact_Phone": "telefon",
    "Terms_Marketing_Processing": "przetwarzanie danych osobowych przez Lekko Life sp. z o.o. z siedzibą w Warszawie w celu wysyłania mi informacji marketingowych dopasowanych do moich preferencji",
    "Terms_Payments_Breadcrumb_Key": "Metody płatności",
    "Terms_Payments_Page_Section1_Header": "Płatność elektroniczna",
    "Terms_Payments_Page_Section1_Paragraph": "Płatności elektroniczne realizujemy za pośrednictwem*:",
    "Terms_Payments_Page_Section1_Paragraph2": "Dzięki temu, na naszej stronie możesz zapłacić korzystając z:",
    "Terms_Payments_Page_Section1_Paragraph3": "A także przelewem bankowym:",
    "Terms_Payments_Page_Section2_Header": "Płatność przy odbiorze",
    "Terms_Payments_Page_Section2_Paragraph": "Płatność przy odbiorze realizujemy wspólnie z naszym partnerem {methods}.",
    "Terms_Payments_Page_Section2_Paragraph2": "W tym celu udostępniamy metodę dostawy “Kurier {methods} za pobraniem”. Płatność przy odbiorze realizowana jest tylko przez kurierów {methods} i wyłącznie przy dostawie na terenie Polski.",
    "Terms_Payments_Page_Section3_Paragraph": "*PayU S.A. z siedzibą w Poznaniu (60-324), ul. Marcelińska 90, KRS nr 0000274399, NIP: 7792308495, REGON: 300523444.\nPayPal (Europe) S.à r.l. et Cie, S.C.A. (zarejestrowana w R.C.S. Luxembourg pod numerem B 118 349), z oficjalną siedzibą w Luksemburgu, L2449\n",
    "Terms_Payments_Page_Supporting_Text": "Nasz sklep obsługuje wszystkie najpopularniejsze metody płatności.",
    "Terms_Return_Breadcrumb_Key": "Zwroty",
    "Terms_Return_Page_Section1_Header": "Jak to zrobić?",
    "Terms_Return_Page_Section1_Paragraph": "Zasady odstąpienia od umowy:",
    "Terms_Return_Page_Section2_Header": "Kiedy otrzymasz zwrot środków?",
    "Terms_Return_Page_Section2_Paragraph": "Zwrot otrzymasz w ciągu 14 dni od otrzymania przez nas zwróconych produktów, po pozytywnym zweryfikowaniu przez nas Twojej przesyłki.",
    "Terms_Return_Page_Section3_Header": "Jak otrzymasz zwrot środków?",
    "Terms_Return_Page_Section3_Paragraph": "Środki zwrócimy Ci w taki sam sposób, jakiego użyłaś do opłacenia zamówienia lub w sposób wskazany w formularzu odstąpienia.",
    "Terms_Return_Page_Section3_Paragraph2": "Jeśli za zamówienie płaciłaś kurierowi przy odbiorze, środki zwrócimy Ci na numer konta podany w formularzu zwrotu.",
    "Terms_Return_Page_Section4_Header": "Pamiętaj!",
    "Terms_Return_Page_Section4_Paragraph": "zwrotowi podlega równowartość ceny zwracanych produktów oraz koszty doręczenia zamówienia do wysokości odpowiadającej najtańszej opcji dostawy",
    "Terms_Return_Page_Section4_Paragraph2": "zwracane produkty wysyłasz do nas na swój koszt - nie przyjmujemy przesyłek za pobraniem",
    "Terms_Return_Page_Section4_Paragraph3": "zwrócić możesz tylko fabrycznie zamknięte produkty",
    "Terms_Return_Page_Section5_Header": "Prawo odstąpienia od umowy nie przysługuje w odniesieniu do produktów:",
    "Terms_Return_Page_Section5_Paragraph": "ulegających szybkiemu zepsuciu lub mających krótki termin przydatności do użycia",
    "Terms_Return_Page_Section5_Paragraph2": "dostarczanych w zapieczętowanych opakowaniach, jeśli zostały otwarte i nie nadają się do zwrotu ze względu na ochronę zdrowia lub ze względów higienicznych*",
    "Terms_Return_Page_Section5_Paragraph3": "*W przypadku otwarcia produktu i stwierdzenia jego wady, przysługuje Ci prawo do reklamacji. Reklamacje można składać mailowo {email} i telefonicznie {phone}.",
    "Terms_Return_Page_Step1_Button_Text": "Pobierz formularz",
    "Terms_Return_Page_Step1_Button_Url": "/formularz-odstapienia-od-umowy-2024-lekko.pdf",
    "Terms_Return_Page_Step1_Count": "Krok 1",
    "Terms_Return_Page_Step1_Headling": "Wypełnij formularz",
    "Terms_Return_Page_Step1_Paragraph": "Wypełnij formularz odstąpienia od umowy",
    "Terms_Return_Page_Step2_Count": "Krok 2",
    "Terms_Return_Page_Step2_Headling": "Wyślij formularz",
    "Terms_Return_Page_Step2_Paragraph": "Wypełniony i podpisany formularz wyślij do nas:",
    "Terms_Return_Page_Step2_Paragraph2": "{bold} na adres:",
    "Terms_Return_Page_Step2_Paragraph2_Bold": "pocztą elektroniczną",
    "Terms_Return_Page_Step2_Paragraph3": "{bold}, dołączając wydrukowany i wypełniony formularz do paczki z produktami",
    "Terms_Return_Page_Step2_Paragraph3_Bold": "pocztą tradycyjną",
    "Terms_Return_Page_Step3_Count": "Krok 3",
    "Terms_Return_Page_Step3_Headling": "Wyślij produkty",
    "Terms_Return_Page_Step3_Paragraph": "Odeślij zamówione produkty na adres:",
    "Terms_Return_Page_Step3_Paragraph2": "Panattoni Park Warsaw West\nLekko Life\nul. Sochaczewska 98C\n05-870 Błonie\n",
    "Terms_Return_Page_Steps_Separator": "lub",
    "Terms_Return_Page_Supporting_Text": "W ciągu 14 dni od odebrania zamówienia, możesz skorzystać ze swojego prawa do odstąpienia od umowy i zwrócić zamówione produkty bez podawania jakiejkolwiek przyczyny.",
    "TotalReview_GoogleOpinions": "Opinie google",
    "TotalReview_GoogleOpinionsScoreInfo": "średnia ocen Lekko w Google",
    "TotalReview_Opinions": "Opinie na naszej stronie",
    "TotalReview_OpinionsScoreInfo": "średnia ocen wszystkich naszych produktów",
    "TotalReview_RecommendInfo": "Polecają znajomym",
    "TotalReview_RecommendInfoSmall": "na podstawie badania Net Promoter Score",
    "TotalReview_RecommendScoreInfo": "klientek poleciłoby nasze produkty znajomym",
    "TotalReview_Subtitle": "",
    "TYP_Error": "Nie udało się przejść do płatności.",
    "TYP_Error_Default": "Spróbuj ponownie.",
    "TYP_Upsell_AddProduct": "{strong} za {price}",
    "TYP_Upsell_AddProduct_Strong": "Dodaj",
    "TYP_Upsell_Buy": "Przejdź do płatności - {total}",
    "TYP_Upsell_CashOnDelivery_Buy": "uzupełnij zamówienie - {total}",
    "TYP_Upsell_Text": "Te produkty wesprą twój codzienny lekki flow. Dodaj do koszyka produkty, a my wyślemy je razem!\n",
    "TYP_Upsell_Title": "Pasują do twojego zamówienia",
    "TYP_Upsell_YourOrder": "Twoje zamówienie",
    "Validation_cardNumber": "Numer karty jest nieprawidłowy",
    "Validation_email": "Adres email jest nieprawidłowy",
    "Validation_maxLength": "Maksymalna długość to {max} znaków",
    "Validation_maxValue": "Maksymalna wartość to {max}",
    "Validation_minLength": "Minimalna długość to {max} znaków",
    "Validation_minValue": "Minimalna wartość to {min}",
    "Validation_nip": "Numer NIP jest niepoprawny",
    "Validation_passwordMinLength": "Hasło musi mieć co najmniej {min} znaków",
    "Validation_phone": "Numer telefonu jest nieprawidłowy",
    "Validation_pin": "Kod PIN jest nieprawidłowy",
    "Validation_postCode": "Kod pocztowy jest niepoprawny",
    "Validation_required": "Pole jest wymagane",
    "Validation_validName": "Pole zawiera niedozwolone znaki",
    "Voucher_Balance_Btn_Label": "Sprawdź",
    "Voucher_Balance_Card_Header": "Twoja karta",
    "Voucher_Balance_Current_Balance": "Do wykorzystania pozostało:",
    "Voucher_Balance_Expiration_Date": "Karta ważna do:",
    "Voucher_Balance_Init_Balance": "Saldo początkowe:",
    "Weekday_0": "Niedziela",
    "Weekday_1": "Poniedziałek",
    "Weekday_2": "Wtorek",
    "Weekday_3": "Środa",
    "Weekday_4": "Czwartek",
    "Weekday_5": "Piątek",
    "Weekday_6": "Sobota",
    "Error_Text": "Wcale nie ma {bold}. Jesteś tu, a tu nic nie ma!",
    "Error_Text_Bold": "lekko",
    "Mobile_Mosaic_Button_Text": "Zobacz produkty",
    "Register_Terms_ConfirmAll_Label": "Odblokuj dostęp do ekskluzywnych treści edukacyjnych, porad ekspertów, spersonalizowanych ofert promocyjnych, a także wsparcia specjalisty i opieki na każdym etapie Twojego zamówienia - zaznacz zgodę na:",
    "Terms_Marketing_Contact_Sms": "sms",
    "Register_Text_2": "Administratorem danych osobowych jest Lekko Life sp. z o.o.  z siedzibą w Warszawie, dane osobowe będą przetwarzane w celu wysyłania informacji handlowych za pośrednictwem wybranego kanału komunikacji. Możesz cofnąć wyrażoną zgodę w każdym czasie bez wpływu na zgodność z prawem przetwarzania dokonanego przed ich wycofaniem. {link} sprawdzisz jak przetwarzamy dane osobowe",
    "Register_Text_2_Link": "TUTAJ"
}
